import React from "react";
export interface LoadingDialogProp {
    color?:string
    text?: string
}
export const LoadingDialog: React.FC<LoadingDialogProp> = ({ text, color="dark" }) => {
  return (
    <div className="text-center my-8">
      <div className={`spinner-border text-${color}`} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}
