import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {FactoryModel} from '../../../../../admin/pages/factories/models'
import {ClientModel} from '../../../../../admin/pages/client/models/index'
import {ProductModel} from '../../../../../admin/pages/products/models/index'
import {BillingCreate} from '../../../../../../../../../base_project_backend/src/api/interfaces/billingCreate.interface'

type Tipo =
  | {tipo: 'factory'; data: FactoryModel[]}
  | {tipo: 'clients'; data: ClientModel[]}
  | {tipo: 'lastBilled'; data: string}

type onLoaded = (res: Tipo) => void

const useLoadForm = (onLoaded?: onLoaded) => {
  const [factory, setFactory] = useState<FactoryModel[]>()
  const [factoryId, setFactoryId] = useState<number | null>(null);
  const [clients, setClients] = useState<ClientModel[]>()
  const [product, setProduct] = useState<ProductModel[]>()
  const [auxProducts, setAuxProducts] = useState<ProductModel[]>()
  const [lastBilled, setLastBilled] = useState<string>('')
  const loadProductByFactory = async (factoryId = 0) => {
    setFactoryId(factoryId !== 0 ? factoryId : null)
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/product/find/toForm/${factoryId}`
    )
    setProduct(response.data)
    setAuxProducts(response.data)
  }

  useEffect(() => {
    const loadFactory = async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/factory`)
      setFactory(response.data)
      onLoaded?.({tipo: 'factory', data: response.data})
    }
    const loadClients = async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/client`)
      setClients(response.data)
      onLoaded?.({tipo: 'clients', data: response.data})
    }
    loadFactory()
    loadClients()
  }, [])
  useEffect(() => {
    const loadLastBilling = async () => {
      try {
        if (factoryId === null) return setLastBilled("")
        const response = await axios.get<{code: string, reference: string | null}>(
          `${process.env.REACT_APP_API_URL}/billingCreate/findLast`,
          { params: { factoryId }}
        )
        console.log('response', response)
        const {code, reference: lastReference} = response.data
        const referenceNumber = Number((lastReference ?? '-0').split('-').pop()) + 1
        const reference = `${code}-` + referenceNumber.toString().padStart(5, '0')
        setLastBilled(reference)
        onLoaded?.({tipo: 'lastBilled', data: reference})
      } catch (error) {
        console.log('error', error)
        setLastBilled("")
        onLoaded?.({tipo: 'lastBilled', data: ""})
      }
    }
    loadLastBilling()
  }, [factoryId])
  return {
    factory,
    loadProductByFactory,
    product,
    auxProducts,
    clients,
    setProduct,
    lastBilled,
    setLastBilled,
  }
}

export default useLoadForm
