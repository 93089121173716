import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/partials/control";
import { useIntl } from "react-intl";
import { useBillingsUIContext, BillingsUIContextModel } from '../BillingsUIContext';

export interface BillingEditDialogHeaderProps {
  id?: number
}
export const  BillingEditDialogHeader: React.FC<BillingEditDialogHeaderProps> = ({ id }) => {

  const billingsUIContext = (useBillingsUIContext() as BillingsUIContextModel);
  const billingsUIProps = useMemo(() => {
    return {
      selectedBilling: billingsUIContext.selectedBilling,
      loadBilling: billingsUIContext.loadBilling,
    };
  }, [billingsUIContext]);
  const intl = useIntl();
  const [title, setTitle] = useState(intl.formatMessage({ id: "BILLING.BILLINGS"}));
  // Title couting
  useEffect(() => {
    let _title = id ? "" : intl.formatMessage({ id: "BILLING.BILLINGS"});
    if (!billingsUIProps.loadBilling && id) {
      _title = `Editar facturación ${billingsUIProps.selectedBilling?.reference}`;
    } 
    if (billingsUIProps.loadBilling) {
      _title = "Cargando "
    }

    setTitle(_title);

    // eslint-disable-next-line
  }, [billingsUIProps.loadBilling, billingsUIProps.selectedBilling]);

  return (
    <>
      {false && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <div className="d-flex">{title} { billingsUIProps.loadBilling && <div className = "dot-shuttle"/> }</div>
        </Modal.Title>
      </Modal.Header>
    </>
  );
}


