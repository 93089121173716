import { useEffect, useState } from 'react'

const useIntlCurrencyFormat = () => {
  const [usa, setUsa] = useState<Intl.NumberFormat>(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}));
  const [cop, setCop] = useState<Intl.NumberFormat>(Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP'}));
  const [thousand, setThousand] =  useState<Intl.NumberFormat>(Intl.NumberFormat('es-CO'));
  useEffect(()=>{
    setUsa(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}));
    setCop(Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP'}));
    setThousand(Intl.NumberFormat('es-CO'))
  },[])

  return {
    usa, cop, thousand
  }
}

export default useIntlCurrencyFormat
