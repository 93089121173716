// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import {IntlShape} from 'react-intl'
import {Visibility} from '@mui/icons-material'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers/AssetHelpers'
import SVG from 'react-inlinesvg'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

interface ActionActionProps {
  openEditBillingDialog: any
  openDeleteBillingDialog: any
  openUpdateStatusIsPaid: any
  openRefundBillingDialog: any
  loadBillings: any
  setIsLoading: any
  intl: IntlShape
}

export const ActionsColumnFormatter = (
  _cellContent: any,
  row: any,
  _rowIndex: any,
  {
    openEditBillingDialog,
    openDeleteBillingDialog,
    openUpdateStatusIsPaid,
    openRefundBillingDialog,
    loadBillings,
    setIsLoading,
  }: ActionActionProps
) => {
  return (
    <>
      <OverlayTrigger
        placement='top'
        delay={{
          show: 500,
          hide: 200,
        }}
        overlay={<Tooltip id='tooltip'>Ver información</Tooltip>}
      >
        <button
          data-tip
          data-for='editTip'
          className='btn btn-icon btn-primary btn-sm m-1'
          onClick={() => openEditBillingDialog(row.id)}
        >
          <span className='svg-icon svg-icon-md svg-icon-primary'>
            <Visibility />
          </span>
        </button>
      </OverlayTrigger>
      {row.deletedAt === null && !row.isPaid && (
        <>
          <OverlayTrigger
            placement='top'
            delay={{
              show: 500,
              hide: 200,
            }}
            overlay={
              <Tooltip id='tooltip'>{row.isPaid ? 'Marcar no paga' : 'Marcar Paga'}</Tooltip>
            }
          >
            <button
              data-tip
              data-for={`isPaid-${row.id}`}
              className={`btn btn-${row.isPaid ? 'warning' : 'success'} btn-icon btn-sm m-1`}
              onClick={() =>
                openUpdateStatusIsPaid(row.id, !row.isPaid, setIsLoading, loadBillings)
              }
            >
              <span
                className={`svg-icon svg-icon-md svg-icon-${row.isPaid ? 'warning' : 'success'}`}
              >
                <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Money.svg')} />
              </span>
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement='top'
            delay={{
              show: 500,
              hide: 200,
            }}
            overlay={<Tooltip id='tooltip'>Eliminar</Tooltip>}
          >
            <button
              data-tip
              data-for={`fullDelete`}
              className={`btn btn-danger btn-icon btn-sm m-1`}
              // ? delete = 2
              onClick={() => openDeleteBillingDialog(row.id, 2)}
            >
              <span className={`svg-icon svg-icon-md svg-icon-danger`}>
                <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
              </span>
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement='top'
            delay={{
              show: 500,
              hide: 200,
            }}
            overlay={<Tooltip id='tooltip'>Editar</Tooltip>}
          >
            <button
              data-tip
              data-for='devTip'
              className='btn btn-icon btn-info btn-sm m-1'
              onClick={() => openRefundBillingDialog(row.id)}
            >
              <span className='svg-icon svg-icon-md svg-icon-primary'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
              </span>
            </button>
          </OverlayTrigger>
        </>
      )}
    </>
  )
}
