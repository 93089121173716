import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
// import * as actions from "../../../_redux/productStocks/productStocksActions";
import { ProductStockEditDialogHeader } from "./ProductStockEditDialogHeader";
import { ProductStockEditForm } from "./ProductStockEditForm";
import { useProductStocksUIContext, ProductStocksUIContextModel } from '../ProductStocksUIContext';
import axios from "axios";
import { ProductStockModel } from '../models/index';
import Swal from 'sweetalert2';

export interface ProductStockEditDialogProps {
  id?: number | null;
  show?: boolean;
  onHide?: () => void;
}
export const ProductStockEditDialog:React.FC<ProductStockEditDialogProps>=({ id, show, onHide }) => {
  // const { company } = useSelector((state) => state.auth);
  // ProductStocks UI Context

  const productStocksUIContext = (useProductStocksUIContext() as ProductStocksUIContextModel);
  const productStocksUIProps = useMemo(() => {
    return {
      queryParams: productStocksUIContext.queryParams,
      initProductStock: productStocksUIContext.initProductStock,
      fetchProductStock: productStocksUIContext.fetchProductStock,
      selectedProductStock: productStocksUIContext.selectedProductStock,
      loadProductStock: productStocksUIContext.loadProductStock,
      loadProductStocks: productStocksUIContext.loadProductStocks,
      setSelectedProductStock: productStocksUIContext.setSelectedProductStock,
      setIsLoading: productStocksUIContext.setIsLoading
    };
  }, [productStocksUIContext]);

  useEffect(() => {
    // server call for getting ProductStock by id
    if (id) {
      productStocksUIProps.fetchProductStock((id as number));
    }
  }, [id]);

  // server request for saving productStock
  const saveProductStock = async(productStock:ProductStockModel) => {
    productStocksUIProps.setIsLoading(true);
    if (!id && !productStock.id) {
      // server request for creating productStock
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/productStock`,
          {
            ...productStock,
          }
        )
        productStocksUIProps.setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: 'Orden de producción creado correctamente',
        }).then(async ()=>{
          await productStocksUIProps.loadProductStocks();
          (onHide as () => void)();
        })
      } catch (error) {
        console.log('error', error)
        productStocksUIProps.setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: 'Error al crear Orden de producción',
        }).then(()=>{
          productStocksUIProps.loadProductStocks()
        })
      }
    } else {
      try {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/productStock/${productStock.id}`,
          {
            ...productStock,
          }
        )
        productStocksUIProps.setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: 'Orden de producción actualizado correctamente',
        }).then(async ()=>{
          await productStocksUIProps.loadProductStocks();
          (onHide as () => void)();
        })

      } catch (error) {
        console.log('error', error)
        productStocksUIProps.setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: 'Error al actualizar el Orden de producción',
        }).then(()=>{
          productStocksUIProps.loadProductStocks()
        })
      }
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={()=>{
        productStocksUIProps.setSelectedProductStock(undefined);
        (onHide as () => void)();
      }}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <ProductStockEditDialogHeader id={(id as number)} />
      {
        id && (productStocksUIProps.selectedProductStock as ProductStockModel)?.id &&
        <ProductStockEditForm
          saveProductStock={saveProductStock}
          actionsLoading={productStocksUIProps.loadProductStock}
          productStock={(productStocksUIProps.selectedProductStock as ProductStockModel) || productStocksUIProps.initProductStock}
          onHide={onHide}
        />
      }
      {
        !id && <ProductStockEditForm
        saveProductStock={saveProductStock}
        actionsLoading={productStocksUIProps.loadProductStock}
        productStock={(productStocksUIProps.selectedProductStock as ProductStockModel) || productStocksUIProps.initProductStock}
        onHide={onHide}
      />
      }
    </Modal>
  );
}


