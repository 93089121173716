import React from 'react'
import {Modal} from '../../_metronic/partials/modals/Modal';

export interface PreloadingPageProps {
  isVisible: boolean;
  setIsVisible?: (isVisible: boolean) => void;
}

export const PreloadingPage: React.FC<PreloadingPageProps> = ({
    setIsVisible,
    isVisible
}) => {
    return(
      <Modal
        isVisible={isVisible}
        closable={false}
        keyboard={false}
        maskClosable={false}
        setIsVisible={setIsVisible}
        newClass='loading-modal-mass'
      >
        <div className="pre-loader">
          <div className="yellow"></div>
          <div className="red"></div>
          <div className="blue"></div>
          <div className="violet"></div>
        </div>
      </Modal>
    );
} 