import React, { useState, useEffect } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers';
import ModalSelectConfig from './content/ModalSelectConfig';
import ConfirmTFA from './content/ConfirmTFA';
//@ts-ignore
import {generateSecret} from '2fa-util';
import { UserComplete } from '../../../auth/models/UserModel';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../../setup/redux/RootReducer';
import DeleteTFA from './content/DeleteTFA';

const ConfigureTFAModal: React.FC = () => {
    const [step, setStep] = useState(1);
    const [qr, setQr] = useState('');
    const [secret, setSecret] = useState('');
    const [otpauth, setOtpauth] = useState('');
    const resultUser: UserComplete = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserComplete;
    useEffect(() => {
        async function generateSecretToken() {
            if(!resultUser.user.haveTwoFactor){
                const result = await generateSecret(resultUser.user.email, resultUser.company.name);
                setQr(result.qrcode)
                setSecret(result.secret)
                setOtpauth(result.otpauth)
            }
        }
        generateSecretToken();
    },[])// eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className='modal fade' id='kt_modal_two_factor_authentication' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h2>{resultUser.user.haveTwoFactor ? 'Eliminar Segundo Factor de Autenticación':'Escoja un metodo de autenticación'}</h2>

                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                    </div>
                </div>

                <div className='modal-body py-lg-10 px-lg-10'>
                    <>
                        {
                            resultUser.user.haveTwoFactor ? <DeleteTFA 
                                userId={resultUser.user.id}
                                twoFactorAuthId={resultUser.user.twoFactorAuths[0].id}
                            /> : <>
                                {
                                    step === 1 && <ModalSelectConfig 
                                        setStep={setStep}
                                    />
                                }
                                {
                                    step === 2 && <ConfirmTFA 
                                        setStep={setStep}
                                        qr={qr}
                                        secret={secret}
                                        userId={resultUser.user.id}
                                        otpauth={otpauth}
                                    />
                                }
                            </>
                        }
                    </>
                </div>
            </div>
        </div>
        </div>
    )
}

export {ConfigureTFAModal}