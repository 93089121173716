import moment from "moment";
import { BootstrapTableProps } from "react-bootstrap-table-next";

export const ProfitsInvestorsStatusCssClasses = ["danger","success","",""];
export const ProfitsInvestorsStatusTitles = ["Titulo1","Titulo2","",""];
export const ProfitsInvestorsTypeCssClasses = ["success","primary",""];
export const ProfitsInvestorsTypeTitles = ["Tipo1","Tipo2",""];
export const defaultSorted: BootstrapTableProps['defaultSorted'] = [{dataField: "id", order: "asc"}];

export const sizePerPageList = [
  {text: '10', value: 10},
  {text: '50', value: 50},
  {text: '100', value: 100},
  {text: '250', value: 250},
  {text: '500', value: 500},
  {text: '1000', value: 1000},
]

export const initialFilter = {
    clientId: null,
    factoryId: null,
    product: "",
    startDate: null,
    endDate: null,
}

export const initialValues = {
    data: {
        entities: [],
        totalCount: 0,
        errorMessage: ''
    },
    totalCount: 0
}