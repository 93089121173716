import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useOrdersUIContext, OrdersUIContextModel } from '../OrdersUIContext';
import { useIntl } from "react-intl";
import useDebounceFnc from "../../../../../../utils/customHooks/useDebounceFnc";
import DatePicker from 'react-datepicker'
import moment from 'moment'

interface filterInterface {
  clientId: number | undefined;
  factoryId: number | undefined;
  type: number | undefined;
  reference: string;
	end?: string;
	start?: string;
}
interface filterValues {
  clientId: string;
  factoryId: string;
  type: string;
  searchText: string;
	end: string;
	start: string;
}
const prepareFilter = (queryParams: any, values : filterValues) => {
  const { clientId, factoryId, type, searchText, end, start } = values;

  const newQueryParams = { ...queryParams };
  const filter : filterInterface = {
    clientId: undefined,
    factoryId: undefined,
    type: undefined,
    reference: '',
		end: undefined,
		start: undefined,
  };

  // Filter by clientId
  filter.clientId = clientId !== "" ? +clientId : undefined;

  //Filter by factoryId
  filter.factoryId = factoryId !== "" ? +factoryId : undefined;

  
  // Filter by type
  filter.type = type !== "" ? +type : undefined;
  // Filter by all fields
  //filter.lastName = searchText;
  if (searchText) {
    filter.reference = searchText;
  }

	if (end) {
		filter.end = end;
	}

	if (start) {
		filter.start = start;
	}

  newQueryParams.filter = filter;
  return newQueryParams;
};

export interface OrdersFilterProp {
  listLoading: boolean
}
export const OrdersFilter: React.FC<{}>=() => {
  // Orders UI Context
  const intl = useIntl();
  const ordersUIContext = (useOrdersUIContext() as OrdersUIContextModel);
  const ordersUIProps = useMemo(() => {
    return {
      queryParams: ordersUIContext.queryParams,
      setQueryParams: ordersUIContext.setQueryParams,
      clients: ordersUIContext.clients,
      factories: ordersUIContext.factories
    };
  }, [ordersUIContext]);
  const [start, setStart] = useState<Date | null>(moment().add(-1, 'M').toDate())
  const [end, setEnd] = useState<Date | null>(moment().toDate())

  // queryParams, setQueryParams,
  const applyFilter = useDebounceFnc((values:any) => {
    const newQueryParams = prepareFilter(ordersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, ordersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      ordersUIProps.setQueryParams(newQueryParams);
    }
  }, 400);


  return (
    <>
      <Formik
        initialValues={{
          clientId: "", 
          factoryId: "",
          type: "",
          searchText: "",
					start: moment().add(-1, 'M').format("YYYY-MM-DD"),
					end: moment().format("YYYY-MM-DD"),
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              
              {/* FILTRAR POR FABRICA */}
              <div className="col-lg-3">
                <select
                  className="form-control"
                  name="factoryId"
                  placeholder={intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_FACTORY"})}
                  onChange={(e) => {
                    setFieldValue("factoryId", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.factoryId}
                >
                  <option value={""}>{intl.formatMessage({id:"LABELS.FILTER.CHOOSE_FACTORY"})}</option>
                  {ordersUIProps.factories.map((f:any,index:number) => (
                    <option value={f.id} key={index}>{f.name}</option>
                  ))}
                </select>
                <small className="form-text text-muted">
                  {intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_FACTORY"})}
                </small>
              </div>
              
              {/* FILTRAR POR CLIENTE */}
              {/* <div className="col-lg-3">
                <select
                  className="form-control"
                  name="clientId"
                  placeholder={intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_CLIENT"})}
                  onChange={(e) => {
                    setFieldValue("clientId", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.clientId}
                >
                  <option value={""}>{intl.formatMessage({id:"LABELS.FILTER.CHOOSE_CLIENT"})}</option>
                  {ordersUIProps.clients.map((c:any,index:number) => (
                    <option value={c.id} key={index}>{c.name}</option>
                  ))}
                </select>
                <small className="form-text text-muted">
                  {intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_CLIENT"})}
                </small>
              </div> */}

              {/* FILTRAR POR REFERNCIA */}
              <div className="col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder={intl.formatMessage({id:"LABELS.FILTER.SEARCH"})}
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                {intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_REFERENCE"})}
                </small>
              </div>

              {/* FILTRAR POR START_DATE */}
              <div className='col-lg-3 mb-2'>
                <DatePicker
                  className='form-control'
                  selected={start}
                  isClearable
                  onChange={(date) => {
                    setStart(date)
                    if (date === null) {
                      setFieldValue('start', '')
                      handleSubmit()
                      return
                    }
                    setFieldValue(
                      'start',
                      moment(date).format('YYYY-MM-DD')
                    )
                    handleSubmit()
                  }}
                />
                <small className='form-text text-muted'>Filtrar por fecha de inicio</small>
              </div>

              {/* FILTRAR POR END_DATE */}
              <div className='col-lg-3 mb-2'>
                <DatePicker
                  className='form-control'
                  selected={end}
                  isClearable
                  onChange={(date) => {
                    setEnd(date)
                    if (date === null) {
                      setFieldValue('end', '');
                      handleSubmit()
                      return
                    }
                    setFieldValue(
                      'end',
                      moment(date).format('YYYY-MM-DD')
                    )
                    handleSubmit()
                  }}
                />
                <small className='form-text text-muted'>Filtrar por fecha final</small>
              </div>
            </div>
          </form>
        )} 
      </Formik>
    </>
  );
}



