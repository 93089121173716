import React from 'react'
import { Card, CardBody } from '../../../../../../_metronic/partials/control/Card';
import { PaymentManufacture } from '../OrdersUIContext';
import DeleteZeroHelper from '../../../../../../utils/functions/DeleteZerosHelper';
import moment from 'moment';

interface PaymentProp {
  type: string;
  payment: PaymentManufacture;
  cop: Intl.NumberFormat;
}

const PaymentDetail: React.FC<PaymentProp> = ({type, payment, cop}) => {
  return (
    <div className='container mt-4 mb-4'>
      <Card className='border rounded'>
        <CardBody>
          <div className='row'>
            <div className="col-lg-12">
              <span> <b>{type === "manufacture" ? "Abono a Manufactura": "Abono a Ganancias"}</b> </span>
            </div>
            <div className="col-lg-12">
              <span> <b>Valor:</b> {DeleteZeroHelper(cop.format(Number(payment.value)))}</span>
            </div>
            <div className="col-lg-12">
              <span> <b>Fecha:</b> {moment(payment.paymentDate).format('DD-MM-YYYY')}</span>
            </div>
            <div className="col-lg-12">
              <span> <b>Descripción:</b> {payment.description}</span>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default PaymentDetail
