import React from "react";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import {AutocompleteRenderInputParams} from '@mui/material'
import AutoComplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export type AutoCompleteInputProps = {
  field: any
  form: any
  label?: string
  withFeedbackLabel: boolean
  customFeedbackLabel:any
  decoration?: string
  type: string
  option: any
  renderInput:(params: AutocompleteRenderInputParams) => React.ReactNode
  getOptionLabel?: (option: any) => string
//   allOptions: 
}
export const AutoCompleteInput: React.FC<AutoCompleteInputProps> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label = "Insumos",
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  decoration,
  option,
  renderInput,
  getOptionLabel,
  ...props
}) => {
  return (
    <>
      <AutoComplete
        options={option}
        id="autocomplete-demo"
        // className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        autoHighlight
        getOptionLabel={getOptionLabel}
        renderInput={(params) => <TextField {...params} label={label} />}
        // {...field}
        {...props}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
