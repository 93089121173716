/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import { UserComplete } from '../../../../app/modules/auth/models/UserModel';
import {RootState} from '../../../../setup'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'
// import {toAbsoluteUrl} from '../../../helpers'
import axios from 'axios';


const HeaderUserMenu: FC = () => {
  const user: UserComplete = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserComplete
  const refreshToken = useSelector<RootState>(({auth}) => auth.refreshToken, shallowEqual);
  const dispatch = useDispatch()

  const logout = async() => {
    await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`,{
      refreshToken
    })
    dispatch(auth.actions.logout())
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {/* <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} /> */}
            <span className="symbol symbol-35 symbol-light-success">
              <span className="symbol-label font-size-h5 font-weight-bold">
                {user.user.names[0]}
              </span>
            </span>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user.user.names} {user.user.lastNames}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user.user.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div> */}

      {/* <div className='separator my-2'></div> */}


      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Cuenta
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Cerrar Sesión
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
