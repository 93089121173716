import React, { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import { Table, Tabs } from 'antd';
import {balanceByClient} from '../WalletUIContext'
import { useWalletUIContext, WalletUIContextModel } from "../WalletUIContext";
import ExcelDetailClient from '../../../../../jsxcomponents/ExcelDetailClient';
import Debts from '../components/Debts';
import Payments from '../components/Payments';
import useLoadCreditors from '../hooks/useLoadCreditors';
import useLoadPayments from "../hooks/useLoadPayments";
import useLoadAllClientBillings from "../hooks/useLoadAllClientBillings";
import '../styles/row-helpers.scss'
export interface WalletEditFormProps{
  data: balanceByClient[];
  tipo: string | null | undefined;
}

export const WalletEditForm: React.FC<WalletEditFormProps> = ({
  data,
  tipo
}) => {

  //Configuracion Contexto
  const walletUIContext = (useWalletUIContext() as WalletUIContextModel);
  const walletUIProps = useMemo(() => {
    return{
      selectedClientBal: walletUIContext.selectedClientBal,
      clientName: walletUIContext.clientName,
      selectedIdWallet: walletUIContext.selectedIdWallet,
    }
  },[walletUIContext])
  const { TabPane } = Tabs;
  const [tabSelected,setTabSelected] = useState("1");
  const { creditors } = useLoadCreditors(walletUIProps.selectedIdWallet);
  const { payments } = useLoadPayments(walletUIProps.selectedIdWallet);
  const { allBillings } = useLoadAllClientBillings(walletUIProps.selectedIdWallet);


  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Remisión',
      dataIndex: 'reference',
      key: 'reference',
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Destino/Origen',
      dataIndex: 'sourceDest',
      key: 'sourceDest',
    },
    {
      title: 'Deuda',
      dataIndex: 'debt',
      key: 'debt',
    },
    {
      title: 'Abono',
      dataIndex: 'payment',
      key: 'payment',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    }
  ];


  return(
      <>
        <Formik
          initialValues={{}}
          enableReinitialize={true}
          onSubmit={() => {
          }}
        >
          {({values}) =>(
            <>
              <Modal.Body>
                <Tabs defaultActiveKey="1" onChange={(key:string) => setTabSelected(key)}>
                    <TabPane tab="Movimientos" key="1">
                      <Table
                        rowClassName={(record) => record.payment.replace(/\s/g, "") !== "$0" ? 'payment' : "debt" }
                        columns={columns}
                        dataSource={data}
                        size='small'
                        scroll={{x:1000}}
                        pagination={{
                          pageSizeOptions: [10, 50,100,500,1000, data?.length ?? 0],
                          showSizeChanger: true,
                          defaultPageSize: 50,
                        }}
                      />
                    </TabPane>
                    {
                      (tipo && tipo !== null && tipo.charAt(tipo.length-1) === 'c') &&
                      <TabPane tab="Deudas" key="2">
                        <Debts
                          creditors={creditors}
                          allBillings={allBillings}
                        />
                      </TabPane>
                    }
                    {
                      (tipo && tipo !== null && tipo.charAt(tipo.length-1) === 'c') &&
                      <TabPane tab="Pagos" key="3">
                        <Payments
                          data={payments}
                        />
                      </TabPane>
                    }
                </Tabs>
              </Modal.Body>
              {tabSelected === "1" && <Modal.Footer>
                <ExcelDetailClient
                  data={data}
                  clientName={walletUIProps.clientName}
                />
              </Modal.Footer>}
            </>
          )}
        </Formik>
      </>
  )
}
