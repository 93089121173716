import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
// import * as actions from "../../../_redux/billings/billingsActions";
import { BillingEditDialogHeader } from "./BillingEditDialogHeader";
import { BillingEditForm } from "./BillingEditForm";
import { useBillingsUIContext, BillingsUIContextModel } from '../BillingsUIContext';
import axios from "axios";
import { BillingModel } from '../models/index';
import Swal from 'sweetalert2';
import { ConfigProvider } from 'antd';
import esEs from 'antd/lib/locale/es_ES';

export interface BillingEditDialogProps {
  id?: number | null;
  show?: boolean;
  onHide?: () => void;
}
export const BillingEditDialog:React.FC<BillingEditDialogProps>=({ id, show, onHide }) => {
  // const { company } = useSelector((state) => state.auth);
  // Billings UI Context

  const billingsUIContext = (useBillingsUIContext() as BillingsUIContextModel);
  const billingsUIProps = useMemo(() => {
    return {
      queryParams: billingsUIContext.queryParams,
      initBilling: billingsUIContext.initBilling,
      fetchBilling: billingsUIContext.fetchBilling,
      selectedBilling: billingsUIContext.selectedBilling,
      loadBilling: billingsUIContext.loadBilling,
      loadBillings: billingsUIContext.loadBillings,
      setSelectedBilling: billingsUIContext.setSelectedBilling,
      setIsLoading: billingsUIContext.setIsLoading
    };
  }, [billingsUIContext]);

  // Billings Redux state
  // const { actionsLoading, billingForEdit } = useSelector(
  //   (state) => ({
  //     actionsLoading: state.billings.actionsLoading,
  //     billingForEdit: state.billings.billingForEdit?.body,
  //   }),
  //   shallowEqual
  // );

  //console.log("state", billingForEdit)

  useEffect(() => {
    // server call for getting Billing by id
    if (id) {
      billingsUIProps.fetchBilling((id as number));
    }
  }, [id]);

  // server request for saving billing
  const saveBilling = async(billing:any) => {
    billingsUIProps.setIsLoading(true);
    if (!id && !billing.id) {
      // server request for creating billing
      try {
        const bill = await axios.post(
          `${process.env.REACT_APP_API_URL}/billing`,
          {
            ...billing,
          }
        )
        if (!bill.data.id) {
          billingsUIProps.setIsLoading(false);
          Swal.fire({
            icon: "warning",
            title: bill.data.description,
          })
        } else {
          billingsUIProps.setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: 'Facturación creada correctamente',
          }).then(async ()=>{
            await billingsUIProps.loadBillings();
            (onHide as () => void)();
          })
        }
      } catch (error) {
        console.log('error', error)
        billingsUIProps.setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: 'Error al crear facturación',
        }).then(()=>{
          billingsUIProps.loadBillings()
        })
      }
    } else {
      try {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/billing/${billing.id}`,
          {
            ...billing,
          }
        )
        billingsUIProps.setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: 'Facturación actualizada correctamente',
        }).then(async ()=>{
          await billingsUIProps.loadBillings();
          (onHide as () => void)();
        })

      } catch (error) {
        console.log('error', error)
        billingsUIProps.setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: 'Error al actualizar facturación',
        }).then(()=>{
          billingsUIProps.loadBillings()
        })
      }
    }
  };


  return (
    <ConfigProvider locale={esEs}>
      <Modal
        size="lg"
        show={show}
        onHide={()=>{
          billingsUIProps.setSelectedBilling(undefined);
          (onHide as () => void)();
        }}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{zIndex:1050}}
      >
        <BillingEditDialogHeader id={(id as number)} />
          {
          !id &&
            <BillingEditForm
              saveBilling={saveBilling}
              actionsLoading={billingsUIProps.loadBilling}
              billing={(billingsUIProps.selectedBilling as BillingModel) || billingsUIProps.initBilling}
              onHide={onHide}
            />
        }
        {
          id && (billingsUIProps.selectedBilling as BillingModel)?.id && <BillingEditForm
              saveBilling={saveBilling}
              actionsLoading={billingsUIProps.loadBilling}
              billing={(billingsUIProps.selectedBilling as BillingModel) || billingsUIProps.initBilling}
              onHide={onHide}
              id={id}
            />
        }
      </Modal>
    </ConfigProvider>
  );
}


