// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
// import * as actions from "../../../_redux/loans/loansActions";
import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/helpers";
import * as uiHelpers from "../LoansUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/partials/control";
import { useLoansUIContext, LoansUIContextModel } from '../LoansUIContext';
import { useIntl } from "react-intl";
import ReactTooltip from 'react-tooltip';
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat';
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper';


export function LoansTable() {
  // const { company } = useSelector((state) => state.auth);
  // Loans UI Context
  const loansUIContext = (useLoansUIContext() as LoansUIContextModel);
  const {cop} = useIntlCurrencyFormat();
  const loansUIProps = useMemo(() => {
    return {
      ids: loansUIContext.ids,
      setIds: loansUIContext.setIds,
      queryParams: loansUIContext.queryParams,
      setQueryParams: loansUIContext.setQueryParams,
      openEditLoanDialog: loansUIContext.openEditLoanDialog,
      openDeleteLoanDialog: loansUIContext.openDeleteLoanDialog,
      tableLoading: loansUIContext.tableLoading,
      valuesTable: loansUIContext.valuesTable,
    };
  }, [loansUIContext]);

  // Getting curret state of loans list from store (Redux)
  // const { currentState } = useSelector(
  //   (state) => ({ currentState: state.loans }),
  //   shallowEqual
  // );
  const { totalCount, data:{entities} } = loansUIProps.valuesTable;
  // Loans Redux state
  // Table columns
  const intl = useIntl();
  
  const columns = [
    {
      dataField: "reference",
      text: "Referencia",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "debtorType",
      text: "Tipo de Deudor",
      sort: true,
      formatter: columnFormatters.StatusColumnFormatter,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "debtorName",
      text: "Deudor",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "lenderName",
      text: "Prestamista",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "value",
      text: "Valor",
      sort: true,
      formatter:(cellContent: any, row: any, rowIndex: any,)=> DeleteZerosHelper(cop.format(Number(row.value))),
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "action",
      // text: intl.formatMessage({ id: "FORMULARIES.ACTIONS"}),
      text: 'Acciones',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditLoanDialog: loansUIProps.openEditLoanDialog,
        openDeleteLoanDialog: loansUIProps.openDeleteLoanDialog,
        intl,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: [...uiHelpers.sizePerPageList, {text: `${totalCount}`, value: totalCount}],
    sizePerPage: loansUIProps.queryParams.pageSize,
    page: loansUIProps.queryParams.pageNumber,
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  })

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={loansUIProps.tableLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                //@ts-ignore
                keyField="id"
                //@ts-ignore
                data={entities}
                //@ts-ignore
                columns={columns}
                //@ts-ignore
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  loansUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: loansUIProps.ids,
                //   setIds: loansUIProps.setIds,
                // })}
                {...(paginationTableProps)}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}



