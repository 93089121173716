// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import {useEffect, useState} from 'react'
import {BillingstatusCssClasses, BillingstatusTitles} from '../../BillingsUIHelpers'

export function StatusColumnFormatter(cellContent: any, row: any) {
  const status = () => {
    if (row.deletedAt !== null) {
      return 2
    }

    if (!row.isPaid && !row.billingNews) {
      return 0
    }

    if (!row.isPaid && row.billingNews) {
      return 3
    }

    if (row.billingNews) {
      return 4
    }

    return 1
  }

  const getLabelCssClasses = () => {
    return `badge badge-lg badge-light-${BillingstatusCssClasses[status()]} badge-inline`
  }

  return <span className={getLabelCssClasses()}>{BillingstatusTitles[status()]}</span>
}
