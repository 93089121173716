import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useLoansUIContext, LoansUIContextModel } from '../LoansUIContext';
import { useIntl } from "react-intl";
import useDebounceFnc from "../../../../../../utils/customHooks/useDebounceFnc";

interface filterInterface {
  debtor: number | undefined;
  debtorName: string;
  type: number | undefined;
  reference: string;
}
interface filterValues {
  debtor: string;
  type: string;
  searchText: string;
  reference: string;
  debtorName: string;
}
const prepareFilter = (queryParams: any, values : filterValues) => {
  const { debtor, type, debtorName, reference } = values;

  const newQueryParams = { ...queryParams };
  const filter : filterInterface = {
    debtor: undefined,
    type: undefined,
    reference: '',
    debtorName: '',
  };

  // Filter by debtor
  filter.debtor = debtor !== "" ? +debtor : undefined;
  // Filter by type
  filter.type = type !== "" ? +type : undefined;

  // Filtrar por cliente,fabrica o referencia
  if (reference) {
    filter.reference = reference;
  }
    if (reference) {
    filter.debtorName = debtorName;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export interface LoansFilterProp {
  listLoading: boolean
}
export const LoansFilter: React.FC<{}>=() => {
  // Loans UI Context
  const intl = useIntl();
  const loansUIContext = (useLoansUIContext() as LoansUIContextModel);
  const loansUIProps = useMemo(() => {
    return {
      queryParams: loansUIContext.queryParams,
      setQueryParams: loansUIContext.setQueryParams,
    };
  }, [loansUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = useDebounceFnc((values:any) => {
    const newQueryParams = prepareFilter(loansUIProps.queryParams, values);
    if (!isEqual(newQueryParams, loansUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      loansUIProps.setQueryParams(newQueryParams);
    }
  }, 400);

  return (
    <>
      <Formik
        initialValues={{
          debtor: "", // values => All=""/Susspended=0/Active=1/Pending=2
          type: "", // values => All=""/Business=0/Individual=1
          debtorName: "",
          reference: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
            <div className="col-lg-3">
                <select
                  className="form-control"
                  name="debtor"
                  placeholder={intl.formatMessage({
                    id: "LABELS.FILTER.FILTER_BY_DEBTOR_TYPE",
                  })}
                  onChange={(e) => {
                    setFieldValue("debtor", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.debtor}
                >
                  <option value="">{intl.formatMessage({id:"LABELS.FILTER.ALL"})}</option>
                  <option value="1">
                    {intl.formatMessage({ id: "CLIENT.CLIENT" })}
                  </option>
                  <option value="2">
                    {intl.formatMessage({ id: "FACTORY.FACTORY" })}
                  </option>
                  <option value="3">
                    Alí
                  </option>
                </select>
                <small className="form-text text-muted">
                  {intl.formatMessage({ id: "LABELS.FILTER.FILTER_BY_DEBTOR_TYPE" })}
                </small>
              </div>
              
              <div className="col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  name="debtorName"
                  placeholder={intl.formatMessage({id:"LABELS.FILTER.SEARCH"})}
                  onBlur={handleBlur}
                  value={values.debtorName}
                  onChange={(e) => {
                    setFieldValue("debtorName", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  Filtrar por deudor
                </small>
              </div>
              
              <div className="col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  name="reference"
                  placeholder={intl.formatMessage({id:"LABELS.FILTER.SEARCH"})}
                  onBlur={handleBlur}
                  value={values.reference}
                  onChange={(e) => {
                    setFieldValue("reference", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  Filtrar por referencia
                </small>
              </div>
            </div>
          </form>
        )} 
      </Formik>
    </>
  );
}



