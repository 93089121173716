// import React from 'react'
// import {MenuItem} from './MenuItem'
// import {MenuInnerWithSub} from './MenuInnerWithSub'
// import {useIntl} from 'react-intl'

export function MenuInner() {
  // const intl = useIntl()
  return (
    <>
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' /> */}
      {/* //TODO Hacer los links de noticias */}
    </>
  )
}
