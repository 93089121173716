import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../_metronic/partials/control";
// import * as actions from "../../../_redux/orders/ordersActions";
import { useOrdersUIContext, OrdersUIContextModel } from '../OrdersUIContext';
import axios from 'axios';
import Swal from 'sweetalert2';
import { OrdersLoadingDialog } from '../orders-loading-dialog/OrdersLoadingDialog';

export interface OrderDeleteDialogProps {
  id?: number | null;
  fullDelete?: number | null;
  show: boolean
  onHide: () => void
}
export const OrderDeleteDialog: React.FC<OrderDeleteDialogProps> = ({ id, fullDelete, show, onHide }) => {
  // Orders UI Context
  const ordersUIContext = (useOrdersUIContext() as OrdersUIContextModel);
  const ordersUIProps = useMemo(() => {
    return {
      setIds: ordersUIContext.setIds,
      queryParams: ordersUIContext.queryParams,
      selectedOrder: ordersUIContext.selectedOrder,
      fetchOrder: ordersUIContext.fetchOrder,
      loadOrder: ordersUIContext.loadOrder,
      loadOrders: ordersUIContext.loadOrders,
      setIsLoading: ordersUIContext.setIsLoading
    };
  }, [ordersUIContext]);
  // Orders Redux state
  // const { isLoading } = useSelector(
  //   (state) => ({ isLoading: state.orders.actionsLoading }),
  //   shallowEqual
  // );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    } else {
      ordersUIProps.fetchOrder((id as number));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  const deleteOrder = async() => {
    onHide();
    ordersUIProps.setIsLoading(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/order/${id}`
      )
      ordersUIProps.setIsLoading(false);
      Swal.fire({
        title: 'Pedido de venta eliminado correctamente',
        icon: 'success'
      }).then(()=>{
        ordersUIProps.loadOrders()
      })
    } catch (error) {
      console.log('error', error)
      ordersUIProps.setIsLoading(false);
      Swal.fire({
        title: 'Error al eliminar pedido de venta',
        icon: 'error'
      }).then(()=>{
        ordersUIProps.loadOrders()
      })
    } 
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {false && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          { ordersUIProps.loadOrder ? 'Cargando...' : `
           "Eliminar" 
          pedido de venta ${ordersUIProps.selectedOrder?.reference}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {ordersUIProps.loadOrder && <OrdersLoadingDialog color={"primary"}/>}
        {!ordersUIProps.loadOrder && (
          <span>
            {"¿Está seguro que desea eliminar este pedido de venta?"}
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        {
          !ordersUIProps.loadOrder && 
            <div>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="button"
                onClick={deleteOrder}
                className={`btn btn-danger btn-elevate`}
              >
                {"Eliminar"}
              </button>
            </div>
        }
      </Modal.Footer>
    </Modal>
  );
}


