import React from "react";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
// import { useIntl } from "react-intl";

const getFieldCSSClasses = (touched:any, errors:any) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export interface InputProps {
  field: any
  form: any
  label?: string
  withFeedbackLabel: boolean
  customFeedbackLabel:any
  decoration?: string
  type: string
}
export const Input: React.FC<InputProps> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  decoration,
  ...props
}) => {
  // const intl = useIntl()
  return (
    <>
      {label && <label> <b>{label}</b> </label>}
      <input
        type={type}
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        {...field}
        {...props}
      />
      {decoration && <span className="input-group-text">@</span>}
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
