import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/partials/control";
import { useIntl } from "react-intl";
import { useProductStocksUIContext, ProductStocksUIContextModel } from '../ProductStocksUIContext';

export interface ProductStockEditDialogHeaderProps {
  id?: number
}
export const  ProductStockEditDialogHeader: React.FC<ProductStockEditDialogHeaderProps> = ({ id }) => {

  const productStocksUIContext = (useProductStocksUIContext() as ProductStocksUIContextModel);
  const productStocksUIProps = useMemo(() => {
    return {
      selectedProductStock: productStocksUIContext.selectedProductStock,
      loadProductStock: productStocksUIContext.loadProductStock,
    };
  }, [productStocksUIContext]);
  const intl = useIntl();
  const [title, setTitle] = useState(intl.formatMessage({ id: "ORDER.ORDERS"}));
  // Title couting
  useEffect(() => {
    let _title = id ? "" : intl.formatMessage({ id: "ORDER.ORDERS"});
    if (!productStocksUIProps.loadProductStock && id) {
      _title = `Editar Pedido de venta ${productStocksUIProps.selectedProductStock?.reference}`;
    }
    if (productStocksUIProps.loadProductStock) {
      _title = "Cargando "
    }

    setTitle(_title);

    // eslint-disable-next-line
  }, [productStocksUIProps.loadProductStock, productStocksUIProps.selectedProductStock]);

  return (
    <>
      {false && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <div className="d-flex">{title} { productStocksUIProps.loadProductStock && <div className = "dot-shuttle"/> }</div>
        </Modal.Title>
      </Modal.Header>
    </>
  );
}


