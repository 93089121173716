import React from "react";
import { LoadingDialog } from "../../../../../../_metronic/partials/control";

export interface LoansLoadingDialogProps {
  color?: string
}
export const LoansLoadingDialog: React.FC<LoansLoadingDialogProps> = ({color}) => {
  return <LoadingDialog color={color} text="Cargando ..." />;
}



