//Librerias
import React from 'react';
import { Route, useHistory } from 'react-router-dom';

//Componentes
import { InventoryCard } from './InventoryCard';
import { InventoryUIProvider } from './InventoryUIContext';


export const InventoryPage = () => {
    const history = useHistory();
    const inventoryUIEvents = {}

    return(
        <InventoryUIProvider inventoryUIEvents={inventoryUIEvents}>
            <Route path="/reports/wallet/:id/edit"> 
                {/* {({ history, match }) =>(
                    // <WalletEditDialog
                    //     show={match !== null}
                    //     id={match && match.params.id}
                    //     onHide={() => {
                    //         history.push("/reports/wallet");
                    //     }}
                    // />
                )} */}
            </Route>
            <InventoryCard />
        </InventoryUIProvider>
    )
}