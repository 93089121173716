import React, {useEffect, useMemo} from 'react'
import {Modal} from 'react-bootstrap'
import {BillingRefundDialogHeader} from './BillingRefundDialogHeader'
import {BillingRefundForm} from './BillingRefundForm'
import {useBillingsUIContext, BillingsUIContextModel} from '../BillingsUIContext'
import axios from 'axios'
import {BillingModel} from '../models/index'
import Swal from 'sweetalert2'
import {ConfigProvider} from 'antd'
import esEs from 'antd/lib/locale/es_ES'

export interface BillingRefundDialogProps {
  id?: number | null
  show?: boolean
  onHide?: () => void
}

export const BillingRefundDialog: React.FC<BillingRefundDialogProps> = ({id, show, onHide}) => {
  const billingsUIContext = useBillingsUIContext() as BillingsUIContextModel
  const billingsUIProps = useMemo(() => {
    return {
      queryParams: billingsUIContext.queryParams,
      initBilling: billingsUIContext.initBilling,
      fetchBilling: billingsUIContext.fetchBilling,
      selectedBilling: billingsUIContext.selectedBilling,
      loadBilling: billingsUIContext.loadBilling,
      loadBillings: billingsUIContext.loadBillings,
      setSelectedBilling: billingsUIContext.setSelectedBilling,
      setIsLoading: billingsUIContext.setIsLoading,
    }
  }, [billingsUIContext])

  useEffect(() => {
    // server call for getting Billing by id
    if (id) {
      billingsUIProps.fetchBilling(id as number)
    }
  }, [id])

  // server request for saving billing
  const saveBilling = async (billing: any) => {
    billingsUIProps.setIsLoading(true)

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/billing/updateOptions/${billing.id}`, {
        ...billing,
      })
      billingsUIProps.setIsLoading(false)
      await Swal.fire({
        icon: 'success',
        title: 'Facturación actualizada correctamente',
      })
      billingsUIProps.loadBillings()
      onHide && onHide()
    } catch (error) {
      console.log('error', error)
      billingsUIProps.setIsLoading(false)

      await Swal.fire({
        icon: 'error',
        title: 'Error al actualizar facturación',
      })
      billingsUIProps.loadBillings()
    }
  }

  return (
    <ConfigProvider locale={esEs}>
      <Modal
        size='lg'
        show={show}
        onHide={() => {
          billingsUIProps.setSelectedBilling(undefined)
          ;(onHide as () => void)()
        }}
        aria-labelledby='example-modal-sizes-title-lg'
        style={{zIndex: 1050}}
      >
        <BillingRefundDialogHeader id={id as number} />
        {id && (billingsUIProps.selectedBilling as BillingModel)?.id && (
          <BillingRefundForm
            saveBilling={saveBilling}
            actionsLoading={billingsUIProps.loadBilling}
            billing={
              (billingsUIProps.selectedBilling as BillingModel) || billingsUIProps.initBilling
            }
            onHide={onHide}
            id={id}
          />
        )}
      </Modal>
    </ConfigProvider>
  )
}
