import React from 'react'
//@ts-ignore
import ReactExport from 'react-data-export';
import { Download } from "@mui/icons-material";
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper';
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat';

const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelFile = ReactExport.ExcelFile;


export interface TotalDebts{
    data: any[],
}

const TotalDebts:React.FC<TotalDebts> = ({
    data,
}) => {

    const {cop} = useIntlCurrencyFormat();
    //@ts-ignore
    let columns = data?.totales
    //@ts-ignore
    let dataAuxiliar = data.data.dataSource

    let O:any = []
    let cont:number = 0
    let OO = []
    let fab:any = []

    fab.push({value: ""})

    OO.push(fab)

    fab = []

    columns.forEach((c:any) => {
        fab.push({value: ""})
        fab.push({value: c.name})
        fab.push({value: DeleteZerosHelper(cop?.format(c.sum))})
        fab.push({value: ""})
    })

    OO.push(fab)


    //@ts-ignore
    dataAuxiliar?.forEach( d => {
        Object.values(d).forEach(o => {
            if(typeof(o) === "string"){
                if(o.substring(o.length - 3) !== "key"){
                    O.push({value: o})
                    cont++
                    if(cont === 3){
                        O.push({value: ''})
                        cont = 0
                    }
                }
            }

        })
        OO.push(O)
        O = []
    })

    let col = []
    col.push({title: ""})
    col.push({title: "Total:"})
    //@ts-ignore
    col.push({title: `${DeleteZerosHelper(cop?.format(data.total))}`, style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "DDDDDD"}}}})

    const multiDataSet = [
        {
            columns: col,
            data: OO
        }
    ];


    return(
        <>
            <ExcelFile
                element={
                    <button
                        type="button"
                        className="btn btn-secondary btn-sm mx-1"
                    >
                        Reporte total deudas <Download />
                    </button>
                }
                filename={`TotalDeudas`}
            >
                <ExcelSheet dataSet={multiDataSet} name={"Total deudas"} />
            </ExcelFile>
        </>
    )
}


export default TotalDebts;
