import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useProductStocksUIContext, ProductStocksUIContextModel } from '../ProductStocksUIContext';
import { useIntl } from "react-intl";
import useDebounceFnc from "../../../../../../utils/customHooks/useDebounceFnc";

interface filterInterface {
  clientId: number | undefined;
  factoryId: number | undefined;
  type: number | undefined;
  name: string;
}
interface filterValues {
  clientId: string;
  factoryId: string;
  type: string;
  searchText: string;
}
const prepareFilter = (queryParams: any, values : filterValues) => {
  const { clientId, factoryId, type, searchText } = values;

  const newQueryParams = { ...queryParams };
  const filter : filterInterface = {
    clientId: undefined,
    factoryId: undefined,
    type: undefined,
    name: ''
  };

  // Filter by clientId
  filter.clientId = clientId !== "" ? +clientId : undefined;

  //Filter by factoryId
  filter.factoryId = factoryId !== "" ? +factoryId : undefined;


  // Filter by type
  filter.type = type !== "" ? +type : undefined;
  // Filter by all fields
  //filter.lastName = searchText;
  if (searchText) {
    filter.name = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export interface ProductStocksFilterProp {
  listLoading: boolean
}
export const ProductStocksFilter: React.FC<{}>=() => {
  // ProductStocks UI Context
  const intl = useIntl();
  const productStocksUIContext = (useProductStocksUIContext() as ProductStocksUIContextModel);
  const productStocksUIProps = useMemo(() => {
    return {
      queryParams: productStocksUIContext.queryParams,
      setQueryParams: productStocksUIContext.setQueryParams,
      clients: productStocksUIContext.clients,
      factories: productStocksUIContext.factories
    };
  }, [productStocksUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = useDebounceFnc((values:any) => {
    const newQueryParams = prepareFilter(productStocksUIProps.queryParams, values);
    if (!isEqual(newQueryParams, productStocksUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      productStocksUIProps.setQueryParams(newQueryParams);
    }
  }, 400);


  return (
    <>
      <Formik
        initialValues={{
          clientId: "",
          factoryId: "",
          type: "",
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">

              {/* FILTRAR POR FABRICA */}
              <div className="col-lg-3">
                <select
                  className="form-control"
                  name="factoryId"
                  placeholder={intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_FACTORY"})}
                  onChange={(e) => {
                    setFieldValue("factoryId", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.factoryId}
                >
                  <option value={""}>{intl.formatMessage({id:"LABELS.FILTER.CHOOSE_FACTORY"})}</option>
                  {productStocksUIProps.factories.map((f:any,index:number) => (
                    <option value={f.id} key={index}>{f.name}</option>
                  ))}
                </select>
                <small className="form-text text-muted">
                  {intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_FACTORY"})}
                </small>
              </div>

              {/* FILTRAR POR CLIENTE */}
              {/* <div className="col-lg-3">
                <select
                  className="form-control"
                  name="clientId"
                  placeholder={intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_CLIENT"})}
                  onChange={(e) => {
                    setFieldValue("clientId", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.clientId}
                >
                  <option value={""}>{intl.formatMessage({id:"LABELS.FILTER.CHOOSE_CLIENT"})}</option>
                  {productStocksUIProps.clients.map((c:any,index:number) => (
                    <option value={c.id} key={index}>{c.name}</option>
                  ))}
                </select>
                <small className="form-text text-muted">
                  {intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_CLIENT"})}
                </small>
              </div> */}

              {/* FILTRAR POR REFERNCIA */}
              <div className="col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder={intl.formatMessage({id:"LABELS.FILTER.SEARCH"})}
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                {intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_REFERENCE"})}
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}



