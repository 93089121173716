import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/partials/control";
import { useIntl } from "react-intl";
import { useOrdersUIContext, OrdersUIContextModel } from '../OrdersUIContext';

export interface OrderEditDialogHeaderProps {
  id?: number
}
export const  OrderEditDialogHeader: React.FC<OrderEditDialogHeaderProps> = ({ id }) => {

  const ordersUIContext = (useOrdersUIContext() as OrdersUIContextModel);
  const ordersUIProps = useMemo(() => {
    return {
      selectedOrder: ordersUIContext.selectedOrder,
      loadOrder: ordersUIContext.loadOrder,
    };
  }, [ordersUIContext]);
  const intl = useIntl();
  const [title, setTitle] = useState(intl.formatMessage({ id: "ORDER.ORDERS"}));
  // Title couting
  useEffect(() => {
    let _title = id ? "" : intl.formatMessage({ id: "ORDER.ORDERS"});
    if (!ordersUIProps.loadOrder && id) {
      _title = `Editar orden de producción ${ordersUIProps.selectedOrder?.reference}`;
    }
    if (ordersUIProps.loadOrder) {
      _title = "Cargando "
    }

    setTitle(_title);

    // eslint-disable-next-line
  }, [ordersUIProps.loadOrder, ordersUIProps.selectedOrder]);

  return (
    <>
      {false && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <div className="d-flex">{title} { ordersUIProps.loadOrder && <div className = "dot-shuttle"/> }</div>
        </Modal.Title>
      </Modal.Header>
    </>
  );
}


