import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
// import * as actions from "../../../_redux/loans/loansActions";
import { LoanEditDialogHeader } from "./LoanEditDialogHeader";
import { LoanEditForm } from "./LoanEditForm";
import { useLoansUIContext, LoansUIContextModel } from '../LoansUIContext';
import axios from "axios";
import { LoanModel } from '../models/index';
import Swal from 'sweetalert2';
import { ConfigProvider } from 'antd';
import esEs from 'antd/lib/locale/es_ES';

export interface LoanEditDialogProps {
  id?: number | null;
  show?: boolean;
  onHide?: () => void;
}
export const LoanEditDialog:React.FC<LoanEditDialogProps>=({ id, show, onHide }) => {
  // const { company } = useSelector((state) => state.auth);
  // Loans UI Context
  
  const loansUIContext = (useLoansUIContext() as LoansUIContextModel);
  const loansUIProps = useMemo(() => {
    return {
      queryParams: loansUIContext.queryParams,
      initLoan: loansUIContext.initLoan,
      fetchLoan: loansUIContext.fetchLoan,
      selectedLoan: loansUIContext.selectedLoan,
      loadLoan: loansUIContext.loadLoan,
      loadLoans: loansUIContext.loadLoans,
      setSelectedLoan: loansUIContext.setSelectedLoan,
      setIsLoading: loansUIContext.setIsLoading
    };
  }, [loansUIContext]);

  // Loans Redux state
  // const { actionsLoading, loanForEdit } = useSelector(
  //   (state) => ({
  //     actionsLoading: state.loans.actionsLoading,
  //     loanForEdit: state.loans.loanForEdit?.body,
  //   }),
  //   shallowEqual
  // );

  //console.log("state", loanForEdit)

  useEffect(() => {
    // server call for getting Loan by id
    if (id) {
      loansUIProps.fetchLoan((id as number));
    }
  }, [id]);

  // server request for saving loan
  const saveLoan = async(loan:LoanModel) => {
    loansUIProps.setIsLoading(true);
    if (!id && !loan.id) {
      // server request for creating loan
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/loan`,
          {
            ...loan,
          }
        )
        loansUIProps.setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: 'Prestamo creado correctamente',
        }).then(async ()=>{
          await loansUIProps.loadLoans();
          (onHide as () => void)();
        })
      } catch (error) {
        console.log('error', error)
        loansUIProps.setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: 'Error al crear prestamo',
        }).then(()=>{
          loansUIProps.loadLoans()
        })
      } 
    } else {
      try {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/loan/${loan.id}`,
          {
            ...loan,
          }
        )
        loansUIProps.setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: 'Prestamo actualizado correctamente',
        }).then(async ()=>{
          await loansUIProps.loadLoans();
          (onHide as () => void)();
        })
        
      } catch (error) {
        console.log('error', error)
        loansUIProps.setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: 'Error al actualizar prestamo',
        }).then(()=>{
          loansUIProps.loadLoans()
        })
      }
    }
  };

  return (
    <ConfigProvider locale={esEs}>
      <Modal
        size="lg"
        show={show}
        onHide={()=>{
          loansUIProps.setSelectedLoan(undefined);
          (onHide as () => void)();
        }}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{zIndex:1050}}
      >
        <LoanEditDialogHeader id={(id as number)} />
        {
          !id &&
            <LoanEditForm
              saveLoan={saveLoan}
              actionsLoading={loansUIProps.loadLoan}
              loan={(loansUIProps.selectedLoan as LoanModel) || loansUIProps.initLoan}
              onHide={onHide}
            />
        }
        {
          id && (loansUIProps.selectedLoan as LoanModel)?.id && <LoanEditForm
            saveLoan={saveLoan}
            actionsLoading={loansUIProps.loadLoan}
            loan={(loansUIProps.selectedLoan as LoanModel) || loansUIProps.initLoan}
            onHide={onHide}
          />
        }
      </Modal>
    </ConfigProvider>
  );
}


