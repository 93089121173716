import React, { useMemo } from 'react';
import { Table } from 'antd';
import { useWalletUIContext, WalletUIContextModel } from '../WalletUIContext';
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper';
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat';
import { paymentsResult } from '../hooks/useLoadPayments';
import ReportPaymentsByClient from '../components/ReportPaymentsByClient';
import { Modal } from "react-bootstrap";


export interface PaymentsProps{
    data: paymentsResult | undefined
}

const Payments: React.FC<PaymentsProps> = ({
    data
}) => {
    const {cop} = useIntlCurrencyFormat()
    const walletUIContext = (useWalletUIContext() as WalletUIContextModel);
    const walletUIProps = useMemo(() => {
        return{
            setSelectedCreditor: walletUIContext.setSelectedCreditor,
            selectedCreditor: walletUIContext.selectedCreditor,
            clientBillingTable: walletUIContext.clientBillingTable
        }
    },[walletUIContext])

    const columns =  [
        {
          title: 'Fecha',
          dataIndex: 'paymentDate',
          key: 'paymentDate',
        },
        {
            title: 'Referencia',
            dataIndex: 'reference',
            key: 'reference'
        },
        {
            title: 'Descripcion',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Destino',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            key: 'value'
        },
    ];


    return(
        <>

            <div className="d-flex">
                <h2>
                    Total abonos: {DeleteZerosHelper(cop?.format(data?.total ? data.total : 0))}      
                </h2>
            </div>

            <div className="form-group row my-4">
                <Table
                    columns={columns}
                    dataSource={data?.payments}
                    scroll={{x:1000}}
                    pagination={{
                        pageSizeOptions: [10, 50,100,500,1000, data?.payments?.length ?? 0],
                        showSizeChanger: true,
                        defaultPageSize: 50,
                    }}
                />
            </div>
            <Modal.Footer>
                <ReportPaymentsByClient
                    data={data}
                />
            </Modal.Footer>
        </>
    )
}

export default Payments