// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {IntlShape} from 'react-intl'
import ReactTooltip from 'react-tooltip'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

interface ActionActionProps {
  openEditPurchaseDialog: any
  openDeletePurchaseDialog: any
  openUpdateStatusIsPaid: any
  openPurchasePaymentDialog: any
  loadPurchases: any
  setIsLoading: any
  intl: IntlShape
}

export const ActionsColumnFormatter = (
  cellContent: any,
  row: any,
  rowIndex: any,
  {
    openEditPurchaseDialog,
    openDeletePurchaseDialog,
    openUpdateStatusIsPaid,
    openPurchasePaymentDialog,
    loadPurchases,
    setIsLoading,
    intl,
  }: ActionActionProps
) => {
  return (
    <>
      <div className='row'>
        {/* EDITAR */}
        <OverlayTrigger
          placement='top'
          delay={{
            show: 500,
            hide: 200,
          }}
          overlay={<Tooltip id='tooltip'>{intl.formatMessage({id: 'GENERAL.EDIT_LABEL'})}</Tooltip>}
        >
          <button
            data-tip
            data-for='editTip'
            className='col-lg-4 m-1 btn btn-icon btn-primary btn-sm'
            onClick={() => openEditPurchaseDialog(row.id)}
          >
            <span className='svg-icon svg-icon-md svg-icon-primary'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
            </span>
          </button>
        </OverlayTrigger>

        {/* ELIMINAR */}
        <OverlayTrigger
          placement='top'
          delay={{
            show: 500,
            hide: 200,
          }}
          overlay={
            <Tooltip id='tooltip'>{intl.formatMessage({id: 'GENERAL.DELETE_LABEL'})}</Tooltip>
          }
        >
          <button
            data-tip
            data-for={`fullDelete`}
            className={`col-lg-4 m-1  btn btn-danger btn-icon btn-sm`}
            // ? delete = 2
            onClick={() => openDeletePurchaseDialog(row.id, 2, row.totalPayment, row.factoryId)}
          >
            <span className={`svg-icon svg-icon-md svg-icon-danger`}>
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
            </span>
          </button>
        </OverlayTrigger>
        {/* Acutalizar */}
        <OverlayTrigger
          placement='top'
          delay={{
            show: 500,
            hide: 200,
          }}
          overlay={<Tooltip id='tooltip'>Realizar abono</Tooltip>}
        >
          <button
            data-tip
            data-for={`isPaid-${row.id}`}
            className={`col-lg-4 m-1 btn btn-success btn-icon btn-sm`}
            onClick={() => openPurchasePaymentDialog(row.id)}
            // onClick={() => openUpdateStatusIsPaid(row.id, !row.isPaid, setIsLoading, loadPurchases)}
          >
            <span className={`svg-icon svg-icon-md svg-icon-success`}>
              <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Money.svg')} />
            </span>
          </button>
        </OverlayTrigger>
      </div>
    </>
  )
}
