import React, {createContext, useContext, useState, useCallback, useEffect} from "react";
import {isEqual, isFunction} from "lodash";
import { initialFilter, initialValues } from "./WalletUIHelpers";
import axios from 'axios';
import { ClientModel } from '../../../admin/pages/client/models';
import useIntlCurrencyFormat from '../../../../../utils/customHooks/useIntlCurrencyFormat';
import DeleteZerosHelper from '../../../../../utils/functions/DeleteZerosHelper';
import moment from 'moment';
//Modelos


//* Interfaces
interface dataDataResultTableWaller {
    entities: any[];
    totalCount: number;
    errorMessage: string;
}

export interface resultTableWallet{
    data: dataDataResultTableWaller;
    totalCount: number;
}

export interface balanceByClient{
    date: Date;
    reference: string;
    description: string;
    sourceDest: string;
    payment: string;
    debt: number;
    client: string;
    total?: string;
}

export interface clientBillings{
    id: number;
    billingDate: Date;
    reference: string;
    name: string;
    value: string
}

export interface resultClientBillings{
    billings: clientBillings[];
    total: number;
}


export interface WalletUIContextModel{
    queryParams: any;
    // setQueryParamsBase: (queryParams:any) => void;
    setQueryParams: (queryParams:any) => void;
    openEditWalletDialog: any;
    valuesTable: resultTableWallet;
    tableLoading: boolean;
    // setTableLoading: (tableLoading: boolean) => void;
    selectedClient:ClientModel | undefined
    setSelectedClient:  React.Dispatch<React.SetStateAction<ClientModel | undefined>>;
    getBalanceByClient: (id:number) => Promise<void>;
    getBalanceByFactory: (id:number) => Promise<void>;
    selectedClientBal: balanceByClient[] | undefined;
    clientName: string;
    accountState: string;
    loadClientBalance: boolean;
    setSelectedClientBal: React.Dispatch<React.SetStateAction<balanceByClient[] | undefined>>
    selectedIdWallet: number | undefined
    setSelectedIdWallet: React.Dispatch<React.SetStateAction<number | undefined>>
    setSelectedCreditor: React.Dispatch<React.SetStateAction<number | null>>
    selectedCreditor: number | null
    clientBillingTable: resultClientBillings | undefined
    setClientBillingTable: React.Dispatch<React.SetStateAction<resultClientBillings | undefined>>

}

const WalletUIContext = createContext<WalletUIContextModel | null>(null);

export function useWalletUIContext(){
    return useContext(WalletUIContext);
}

export const WalletUIConsumer = WalletUIContext.Consumer;

export type WalletUIProviderProps = {
    walletUIEvents: any;
}

export const WalletUIProvider:React.FC<WalletUIProviderProps> = ({walletUIEvents, children}) => {
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const [valuesTable,setValuesTable] = useState<resultTableWallet>(initialValues);
    const [tableLoading, setTableLoading] = useState(true);
    const [selectedClient, setSelectedClient] = useState<ClientModel>();
    const {cop} = useIntlCurrencyFormat();
    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {

          if (isFunction(nextQueryParams)) {
            nextQueryParams = nextQueryParams(prevQueryParams);
          }

          if (isEqual(prevQueryParams, nextQueryParams)) {
            return prevQueryParams;
          }

          return nextQueryParams;
        });
    }, []);
    const [loadClientBalance,setLoadClientBalane] = useState(false);

    const [selectedClientBal,setSelectedClientBal] = useState<balanceByClient[]>()
    const [clientName, setClientName] = useState<string>("")
    const [accountState,setAccountState] = useState<string>("");
    const [selectedIdWallet,setSelectedIdWallet] = useState<number | undefined>();
    const [selectedCreditor,setSelectedCreditor] = useState<number | null>(0);
    const [clientBillingTable,setClientBillingTable] = useState<resultClientBillings | undefined>();

    //*Llamadas al backend
    const loadBalance = async () => {
        setSelectedClient(undefined);
        setTableLoading(true);
        try {
            const balanceResult = await axios.post(`${process.env.REACT_APP_API_URL}/client/getBalanceTable`,{
            queryParams,
            });
            (balanceResult.data as unknown as resultTableWallet).data.entities.map((e:any) =>{
                e.totalBillings = DeleteZerosHelper(cop.format(Number(e.totalBillings)));
                e.totalLoans = DeleteZerosHelper(cop.format(Number(e.totalLoans)));
                e.totalPayme = DeleteZerosHelper(cop.format(Number(e.totalPayme)));
                e.totalDebts = DeleteZerosHelper(cop.format(Number(e.totalDebts)));
                e.totalPayments = DeleteZerosHelper(cop.format(Number(e.totalPayments)));
                e.balance = DeleteZerosHelper(cop.format(Number(e.balance)));
            })
            setValuesTable(balanceResult.data);
        } catch (error) {
            console.log('error', error)
        } finally {
            setTableLoading(false);
        }
    }

    const getBalanceByClient = useCallback(async(id:number)=> {
        try{
            setLoadClientBalane(true);
            setSelectedIdWallet(id);
            const result = await axios.get(`${process.env.REACT_APP_API_URL}/client/getBalanceByClient/${id}`);

            (result.data as unknown as balanceByClient[]).map(
                (e:any,index:number) => {
                    e.key = index
                    e.payment = DeleteZerosHelper(cop?.format(e.payment))
                    e.debt = DeleteZerosHelper(cop?.format(e.debt))
                    e.date = moment(e.date).format('L')
                    e.total = DeleteZerosHelper(cop?.format(e.total))
                }
            )

            setSelectedClientBal(result.data)
            setClientName(result.data[0].client)
            setAccountState(result.data[result.data.length-1].total)
        }catch(error){
            console.log('error loading balance',error)
        }finally{
            setTimeout(()=>{
                setLoadClientBalane(false);
            },1500)
        }

    },[])



    const getBalanceByFactory = useCallback(async(id:number)=> {
        try{
            setLoadClientBalane(true);
            setSelectedIdWallet(id);
            const result = await axios.get(`${process.env.REACT_APP_API_URL}/factory/getBalanceByFactory/${id}`);

            (result.data as unknown as balanceByClient[]).map(
                (e:any,index:number) => {
                    e.key = index
                    e.payment = DeleteZerosHelper(cop?.format(e.payment))
                    e.debt = DeleteZerosHelper(cop?.format(e.debt))
                    e.date = moment(e.date).format('L')
                    e.total = DeleteZerosHelper(cop?.format(e.total))
                }
            )
            setSelectedClientBal(result.data)
            setClientName(result.data[0].client)
            setAccountState(result.data[result.data.length-1].total)
        }catch(error){
            console.log('error loading balance',error)
        }finally{
            setTimeout(()=>{
                setLoadClientBalane(false);
            },1500)
        }

    },[])

    const getClientBillingsByFactory = useCallback(async () => {
        try {
            const billingsResult = await axios.get(`${process.env.REACT_APP_API_URL}/billing/getClientBillingsByFactory/${selectedCreditor}/${selectedIdWallet}`);

            (billingsResult.data.billings as unknown as clientBillings[]).map((e:any,index:number) => {
                e.billingDate = moment(e.date).format('L')
                e.value = DeleteZerosHelper(cop?.format(e.value))
            })

            setClientBillingTable(billingsResult.data)
        } catch (error) {
            console.log('error', error)
        }
    },[])



    useEffect(() => {
        loadBalance();
    },[queryParams])

    useEffect(() => {
        if(selectedCreditor !== 0){
            getClientBillingsByFactory()
        }
    },[selectedCreditor])


    const value = {
        queryParams,
        setQueryParams,
        openEditWalletDialog: walletUIEvents.openEditWalletDialog,
        valuesTable,
        tableLoading,
        selectedClient,
        setSelectedClient,
        loadBalance,
        getBalanceByClient,
        getBalanceByFactory,
        selectedClientBal,
        clientName,
        accountState,
        loadClientBalance,
        setSelectedClientBal,
        selectedIdWallet,
        setSelectedIdWallet,
        setSelectedCreditor,
        selectedCreditor,
        clientBillingTable,
        setClientBillingTable
    }


    return <WalletUIContext.Provider value={value}>{children}</WalletUIContext.Provider>
}
