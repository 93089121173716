import React, {useEffect, useState} from 'react'
import type {ProfitsSumary, ProfitsUIContextModel, QueryParams} from '../ProfitsUIContext'
import {useProfitsUIContext} from '../ProfitsUIContext'
import axios, {CancelToken} from 'axios'
import useDebounceFnc from '../../../../../../utils/customHooks/useDebounceFnc'

const useBillingProfitsSumary = (queryParams: QueryParams) => {
  const [sumary, setSumary] = useState<ProfitsSumary | null>(null)

  const loadSumary = useDebounceFnc(async (query: QueryParams, cancelToken: CancelToken) => {
    try {
      setSumary(null)
      const response = await axios.get<ProfitsSumary>(
        `${process.env.REACT_APP_API_URL}/billing/getBillingsProfitsSumary`,
        {
          params: {
            clientId: query.clientId ?? undefined,
            factoryId: query.factoryId ?? undefined,
            product: query.product,
            startDate: query.startDate ?? undefined,
            endDate: query.endDate ?? undefined,
          },
          cancelToken,
        }
      )
      setSumary(response.data)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }, 500)

  useEffect(() => {
    const source = axios.CancelToken.source()

    if (queryParams !== null) {
      loadSumary(queryParams, source.token)
    }

    return () => {
      source.cancel()
    }
  }, [
    queryParams.clientId,
    queryParams.factoryId,
    queryParams.product,
    queryParams.startDate,
    queryParams.endDate,
  ])

  return sumary
}

export default useBillingProfitsSumary
