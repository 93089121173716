import React, { useState, useEffect } from 'react';
import { ProductModel } from '../../../../admin/pages/products/models/index';
import { AvailableProduct } from '../models/index';
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat';
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers/AssetHelpers';

interface ProductTableProps {
    id:number
    products: AvailableProduct[];
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    onDelete?: (product:AvailableProduct, setFieldValue:(field: string, value: any, shouldValidate?: boolean | undefined) => void) => void;
}
const ProductTable: React.FC<ProductTableProps> = ({
    id,
    products,
    setFieldValue,
    onDelete,
}) => {
    const {cop} = useIntlCurrencyFormat();

    const groupedByReferenceProducts: AvailableProduct[] = products.reduce(
      (prev: AvailableProduct[], curr: AvailableProduct) => {
        const product = prev.find((p) => p.productName === curr.productName);
        if (product === undefined) return [...prev, {...curr}];
        product.availableQuantity += curr.availableQuantity;
        product.requestedQuantity += curr.requestedQuantity;
        product.restQuantity += curr.restQuantity;

        return prev
      },
      []
    )

    return(
        <>
            <h1>Productos </h1>
            <div className='table-responsive'>
                <table className="styled-table-info">
                    <thead>
                        <tr>
                            <th className="text-center">Producto</th>
                            <th className="text-center">Precio</th>
                            <th className="text-center">Cantidad disponible</th>
                            <th className="text-center">Cantidad requerida</th>
                            <th className="text-center">Cantidad restante</th>
                            <th className="text-center">Total</th>
                            {
                                !id &&
                                    <th className="text-center">Acción</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            groupedByReferenceProducts.map((item,index) => (
                                <tr key={index}>
                                    <td align='center'>{item.productName}</td>
                                    <td align='center'>{DeleteZerosHelper(cop?.format(Number(item.price)))}</td>
                                    <td align='center'>{item.availableQuantity}</td>
                                    <td align='center'>{item.requestedQuantity}</td>
                                    <td align='center'>{item.restQuantity}</td>
                                    <td align='center'>{DeleteZerosHelper(cop?.format(Number(item.requestedQuantity * item.price)))}</td>
                                    {
                                        !id &&
                                            <td align='center'>
                                                {/* @ts-ignore */}
                                                <button type='button' className='btn btn-sm btn-danger' onClick={() => onDelete(item, setFieldValue)}>
                                                    <span className='svg-icon svg-icon-md svg-icon-danger'>
                                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                    </span>
                                                </button>
                                            </td>
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
};
export default ProductTable;
