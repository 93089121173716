import React, {createContext, useContext, useState, useCallback, useEffect} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter, initialValues} from "./BillingsUIHelpers";
import axios from "axios";
import { BillingModel } from "./models";
import useIntlCurrencyFormat from "../../../../../utils/customHooks/useIntlCurrencyFormat";
import DeleteZerosHelper from "../../../../../utils/functions/DeleteZerosHelper";

//Modelos
import { ClientModel } from '../../../admin/pages/client/models/index';
import { FactoryModel } from "../../../admin/pages/factories/models";

interface dataDataResultTableBillings {
  entities: any[],
  totalCount: number,
  errorMessage: string,
}
export interface resultTableBillings {
  data: dataDataResultTableBillings
  totalCount: number,
}
export interface BillingsUIContextModel {
  queryParams:any;
  setQueryParamsBase: (queryParams:any) => void;
  ids: number[];
  setIds: (ids: number[]) => void;
  setQueryParams: (queryParams:any) => void;
  initBilling:Partial<BillingModel>;
  newBillingButtonClick: any;
  openEditBillingDialog: any;
  openDeleteBillingDialog: any;
  openDeleteBillingsDialog: any;
  openFetchBillingsDialog: any;
  openUpdateBillingsStatusDialog: any;
  openUpdateStatusIsPaid: any;
  openRefundBillingDialog: any;
  valuesTable: resultTableBillings;
  tableLoading: boolean;
  // setTableLoading: (tableLoading: boolean) => void;
  fetchBilling: (id: number) => Promise<void>;
  loadBillings: () => Promise<void>;
  loadBilling: boolean;
  selectedBilling:BillingModel | undefined;
  setSelectedBilling:  React.Dispatch<React.SetStateAction<BillingModel | undefined>>;
  factories: FactoryModel[];
  clients: ClientModel[];
  isLoading: boolean;
  setIsLoading: (isLoading:boolean) => void;
}
const BillingsUIContext = createContext<BillingsUIContextModel | null>(null);

export function useBillingsUIContext() {
  return useContext(BillingsUIContext);
}

export const BillingsUIConsumer = BillingsUIContext.Consumer;

export type BillingsUIProviderProps = {
  billingsUIEvents: any,
}

export const BillingsUIProvider:React.FC<BillingsUIProviderProps> =  ({billingsUIEvents, children}) => {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [valuesTable, setValuesTable] = useState<resultTableBillings>(initialValues);
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedBilling, setSelectedBilling] = useState<BillingModel>();
  const [loadBilling, setLoadBilling] = useState(false);
  const [ids, setIds] = useState<number[]>([]);
  const {cop} = useIntlCurrencyFormat();

  const [clients,setClients] = useState<ClientModel[]>([]);
  const [isLoading,setIsLoading] = useState(false);

  const [factories,setFactories] = useState<FactoryModel[]>([]);

  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {

      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initBilling: Partial<BillingModel> = {
    id: undefined,
    description: "",
    reference: "",
    factoryId: 0,
    clientId: 0,
    orderId: 0,
    price: 0,
    quantity: 0,
  };

  const loadBillings = async () => {
    setSelectedBilling(undefined);
    setTableLoading(true);
    try {
      const billingResult = await axios.post(`${process.env.REACT_APP_API_URL}/billing/findAll`,{
        queryParams,
      });
      (billingResult.data as unknown as resultTableBillings).data.entities.map((e:any)=>{
        e.billedPrice = DeleteZerosHelper(cop?.format(e.billedPrice));
      })
      setValuesTable(billingResult.data);
    } catch (error) {
      console.log('error', error)
    } finally {
      setTableLoading(false);
    }
  }

  const loadFactories = async ()=>{
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/factory`);
      setFactories(response.data);
    } catch (error) {
    } finally {

    }
  }

  const loadClients = async ()=>{
    try {
        const result = await axios.get(`${process.env.REACT_APP_API_URL}/client`);
        setClients(result.data);
    } catch (error) {
        console.log('error', error)
    } finally {}
  }


  useEffect(()=> {
    loadBillings();
    loadFactories();
    loadClients();
  },[queryParams])

  const fetchBilling = useCallback(async (id:number)=> {
    try {
      setLoadBilling(true)
      const result = await axios.get(`${process.env.REACT_APP_API_URL}/billing/${id}`);
      setSelectedBilling(result.data);
    } catch (error) {
      console.log('error loading billing', error)
    } finally {
      setTimeout(()=>{
        setLoadBilling(false);
      },1500)
    }
  },[])

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initBilling,
    newBillingButtonClick: ()=>{
      setSelectedBilling(undefined);
      billingsUIEvents.newBillingButtonClick();
    },
    openEditBillingDialog: billingsUIEvents.openEditBillingDialog,
    openDeleteBillingDialog: billingsUIEvents.openDeleteBillingDialog,
    openDeleteBillingsDialog: billingsUIEvents.openDeleteBillingsDialog,
    openFetchBillingsDialog: billingsUIEvents.openFetchBillingsDialog,
    openUpdateStatusIsPaid: billingsUIEvents.openUpdateStatusIsPaid,
    openUpdateBillingsStatusDialog: billingsUIEvents.openUpdateBillingsStatusDialog,
    openRefundBillingDialog: billingsUIEvents.openRefundBillingDialog,
    valuesTable,
    tableLoading,
    loadBillings,
    fetchBilling,
    selectedBilling,
    setSelectedBilling,
    loadBilling,
    factories,
    clients,
    isLoading,
    setIsLoading
  };

  return <BillingsUIContext.Provider value={value}>{children}</BillingsUIContext.Provider>;
}


