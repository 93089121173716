import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios';
import { useWalletUIContext, WalletUIContextModel } from '../WalletUIContext';
import moment from 'moment';
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper';
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat';
const regexNumber = /-?\d/g;
export interface childrenColumns{
    title: string;
    dataIndex: string;
    key: string;
}

export interface columnsAllClientBillings{
    title: string;
    children: childrenColumns[];
}



export interface dataAllClientBillings{
    columns: columnsAllClientBillings[];
    dataSource: any[];
}

export interface totalesAllClientBillings{
    name: string;
    sum: number
}


export interface allClientBillings{
    data: dataAllClientBillings;
    totales: totalesAllClientBillings[];
    total: number;
}



const useLoadAllClientBillings = (id: number | undefined) => {
    const [allBillings, setAllBillings] = useState<allClientBillings>()
    const walletUIContext = (useWalletUIContext() as WalletUIContextModel);
    const {cop} = useIntlCurrencyFormat();
    const walletUIProps = useMemo(() => {
      return{
        selectedIdWallet: walletUIContext.selectedIdWallet,
      }
    },[walletUIContext.selectedIdWallet])


    useEffect(() => {
        const loadPayments = async () => {
            if(id){
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/billing/getAllClientBillings/${id}`);
                let factId:string[];
                if(response.data.data.dataSource.length > 0){
                    (response.data.data.dataSource as unknown as any[]).map((e: any, index:number) => {
                        factId = Object.keys(e).map(s => {
                            const numberId = s.match(regexNumber);
                            return (numberId as RegExpMatchArray)[0];
                        })
                        factId = factId.filter((item,index) => factId.indexOf(item) === index)
                        factId.forEach(f => {
                            e[`billingDate${f}`] = e[`billingDate${f}`] === '' ? "" : moment(e[`billingDate${f}`]).format('L')
                            e[`value${f}`] = e[`value${f}`] === '' ? "" : DeleteZerosHelper(cop?.format(e[`value${f}`]))
                        })
                        e.key = `${index}key` //*Para evitar que tire error de "keys" repetidas al momento de colocar los datos en la tabla
                    })
                }
                setAllBillings(response.data)
            }
        }
        loadPayments()
    },[walletUIProps.selectedIdWallet])

    return {
        allBillings
    }
}

export default useLoadAllClientBillings
