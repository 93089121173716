import React from 'react';
import { Modal as ModalAntd } from 'antd';



//Interfaces
export interface ModalProps {
    title?: string;
    isVisible: boolean;
    setIsVisible?: (isVisible: boolean) => void ;
    closable?: boolean;
    keyboard?: boolean;
    maskClosable?: boolean;
    newClass?: string;
    width?: string;
}


export const Modal:React.FC<ModalProps> = ({
    children,
    title,
    isVisible,
    setIsVisible,
    closable,
    keyboard,
    maskClosable,
    newClass,
    width,
}) => {
    return(
       <ModalAntd
        title={title}
        centered
        visible={isVisible}
        closable={closable === false ? false : true}
        keyboard={keyboard === false ? false : true}
        maskClosable={maskClosable === false ? false : true}
        wrapClassName={newClass}
        onCancel={() => {
            setIsVisible?.(false);
            // props.canceledModal
        }}
        zIndex={1100}
        footer={false}
        width={width && width}
       >
           {children}
       </ModalAntd>
    )
}
