import React, {useState, useEffect, useMemo} from 'react'
import { ClientModel } from '../../../../../admin/pages/client/models/index';
import { FactoryModel } from '../../../../../admin/pages/factories/models/index';
import axios from 'axios';
import { ProductOrderInterfaceForm, ProductRealData } from '../../../../../admin/pages/products/models';
import { ProductStockModel } from '../../models';
import { getDifference } from '../../../../../../../utils/differenceObjArr';
import { FormikProps } from 'formik';
import { useProductStocksUIContext, ProductStocksUIContextModel} from '../../ProductStocksUIContext';
interface LoadFormProps {
    productStock: Partial<ProductStockModel> | undefined,
    setAddProduct: React.Dispatch<React.SetStateAction<ProductOrderInterfaceForm[]>>
    formRef: React.RefObject<FormikProps<ProductStockModel>>
}
interface IndexAux{
    id: number;
}
const useLoadForms = ({
    productStock,
    setAddProduct,
    formRef,
}:LoadFormProps) => {
    const [clients, setClient] = useState<ClientModel[]>([]);
    const [factories, setFactories] = useState<FactoryModel[]>([]);
    const [products, setProducts] = useState<ProductOrderInterfaceForm[]>([]);
    const [allProducts, setAllProducts] = useState<ProductOrderInterfaceForm[]>([]);
    const [selectedProducts, setSelectedProduct] = useState<ProductOrderInterfaceForm | null>(null);

    //Contexto
    const productStocksUIContext = (useProductStocksUIContext() as ProductStocksUIContextModel);
    const productStocksUIProps = useMemo(() => {
    return {
        factoryId: productStocksUIContext.factoryId,
        setFactoryId: productStocksUIContext.setFactoryId
    };
  }, [productStocksUIContext]);


    useEffect(()=>{
        const loadClients = async ()=>{
            try {
                const result = await axios.get(`${process.env.REACT_APP_API_URL}/client`);
                setClient(result.data);
            } catch (error) {
                console.log('error', error)
            } finally {}
        }
        const loadFactories = async () => {
            try {
                const result = await axios.get(`${process.env.REACT_APP_API_URL}/factory`);
                setFactories(result.data);
            } catch (error) {

            } finally {}
        }
        // const loadProducts = async ()=>{
        //     try {
        //         const result = await axios.get(`${process.env.REACT_APP_API_URL}/product/find/toForm`);
        //         setProducts(result.data);
        //         setAllProducts(result.data);
        //         if(productStock?.productsId){
        //             const aux: ProductOrderInterfaceForm[] = [];
        //             const indexAux: IndexAux[] = [];
        //             (productStock?.products as ProductRealData[]).map(
        //               (product: ProductRealData) => {
        //                 (aux as ProductOrderInterfaceForm[]).push({
        //                   id: Number(product.productId),
        //                   price: product.price,
        //                   quantity: product.quantity,
        //                   name: product.name,
        //                   cost: 0,
        //                   mge: 0,
        //                   suggestedPrice: 0,
        //                 })
        //                 indexAux.push({
        //                   id: Number(product.productId)
        //                 })
        //               }
        //             )
        //             setAddProduct(aux);
        //             setProducts(getDifference(result.data,indexAux))
        //             formRef.current?.setFieldValue('values',JSON.stringify(aux));
        //           }
        //     } catch (error) {

        //     } finally {}
        // }
        loadClients()
        loadFactories()
        // loadProducts()
    },[])


    useEffect(() => {
        const loadProducts = async ()=>{
            try {
                const result = await axios.get(`${process.env.REACT_APP_API_URL}/product/find/toForm/${productStocksUIProps.factoryId}`);
                setProducts(result.data);
                setAllProducts(result.data);
                if(productStock?.productsId){
                    const aux: ProductOrderInterfaceForm[] = [];
                    const indexAux: IndexAux[] = [];
                    (productStock?.products as ProductRealData[]).map(
                      (product: ProductRealData) => {
                        (aux as ProductOrderInterfaceForm[]).push({
                          id: Number(product.productId),
                          price: product.price,
                          quantity: product.quantity,
                          name: product.name,
                          cost: 0,
                          mge: 0,
                          suggestedPrice: 0,
                        })
                        indexAux.push({
                          id: Number(product.productId)
                        })
                      }
                    )
                    setAddProduct(aux);
                    setProducts(getDifference(result.data,indexAux))
                    formRef.current?.setFieldValue('values',JSON.stringify(aux));
                }
            } catch (error) {

            } finally {
            }
        }
        loadProducts()
    },[productStocksUIProps.factoryId])

    return {
        clients,
        factories,
        products,
        setProducts,
        allProducts,
        selectedProducts,
        setSelectedProduct,
    }
}

export default useLoadForms
