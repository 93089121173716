import {BootstrapTableProps} from 'react-bootstrap-table-next'

export const LoanstatusCssClasses = ['primary', 'warning', 'success', '']
export const LoanstatusTitles = ['Inactivo', 'Activo', '', '']
export const LoanTypeCssClasses = ['success', 'primary', '']
export const LoanTypeTitles = ['Business', 'Individual', '']
export const defaultSorted: BootstrapTableProps['defaultSorted'] = [{dataField: 'id', order: 'asc'}]

export const sizePerPageList = [
  {text: '10', value: 10},
  {text: '50', value: 50},
  {text: '100', value: 100},
  {text: '250', value: 250},
  {text: '500', value: 500},
  {text: '1000', value: 1000},
]

export const initialFilter = {
  filter: {
    nom_area: '',
    tel_area: '',
  },
  sortOrder: 'asc', // asc||desc
  sortField: 'id',
  pageNumber: 1,
  pageSize: 50,
}

export const initialValues = {
  data: {
    entities: [],
    totalCount: 0,
    errorMessage: '',
  },
  totalCount: 0,
}

// export const initialFilter = {
//   filter: {
//     lastName: "",
//     firstName: "",
//     email: "",
//     ipAddress: ""
//   },
//   sortOrder: "asc", // asc||desc
//   sortField: "id",
//   pageNumber: 1,
//   pageSize: 10
// };
