import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
// import * as actions from "../../../_redux/purchases/purchasesActions";
import { PurchaseEditDialogHeader } from "./PurchaseEditDialogHeader";
import { PurchaseEditForm } from "./PurchaseEditForm";
import { usePurchasesUIContext, PurchasesUIContextModel } from '../PurchasesUIContext';
import axios from "axios";
import { PurchaseModel } from '../models/index';
import Swal from 'sweetalert2';

export interface PurchaseEditDialogProps {
  id?: number | null;
  show?: boolean;
  onHide?: () => void;
}
export const PurchaseEditDialog:React.FC<PurchaseEditDialogProps>=({ id, show, onHide }) => {
  // const { company } = useSelector((state) => state.auth);
  // Purchases UI Context
  
  const purchasesUIContext = (usePurchasesUIContext() as PurchasesUIContextModel);
  const purchasesUIProps = useMemo(() => {
    return {
      queryParams: purchasesUIContext.queryParams,
      initPurchase: purchasesUIContext.initPurchase,
      fetchPurchase: purchasesUIContext.fetchPurchase,
      selectedPurchase: purchasesUIContext.selectedPurchase,
      loadPurchase: purchasesUIContext.loadPurchase,
      loadPurchases: purchasesUIContext.loadPurchases,
      setSelectedPurchase: purchasesUIContext.setSelectedPurchase,
      setIsLoading: purchasesUIContext.setIsLoading,
      paymentProviders: purchasesUIContext.paymentProviders,
      loadPaymentP: purchasesUIContext.loadPaymentP,
      loadPaymentProviderByPurchaseId: purchasesUIContext.loadPaymentProviderByPurchaseId,
    };
  }, [purchasesUIContext]);

  // Purchases Redux state
  // const { actionsLoading, purchaseForEdit } = useSelector(
  //   (state) => ({
  //     actionsLoading: state.purchases.actionsLoading,
  //     purchaseForEdit: state.purchases.purchaseForEdit?.body,
  //   }),
  //   shallowEqual
  // );

  //console.log("state", purchaseForEdit)

  useEffect(() => {
    // server call for getting Purchase by id
    if (id) {
      purchasesUIProps.fetchPurchase((id as number));
      purchasesUIProps.loadPaymentProviderByPurchaseId(id as number)
    }
  }, [id]);

  // server request for saving purchase
  const savePurchase = async(purchase:PurchaseModel) => {
    purchasesUIProps.setIsLoading(true);
    if (!id && !purchase.id) {
      // server request for creating purchase
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/purchase`,
          {
            ...purchase,
          }
        )
        purchasesUIProps.setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: 'Compra creada correctamente',
        }).then(async ()=>{
          await purchasesUIProps.loadPurchases();
          (onHide as () => void)();
        })
      } catch (error) {
        console.log('error',error);
        purchasesUIProps.setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: 'Error al crear compra',
        }).then(()=>{
          purchasesUIProps.loadPurchases()
        })
      } finally {
      }
    } else {
      try {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/purchase/${purchase.id}`,
          {
            ...purchase,
          }
        )
        purchasesUIProps.setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: 'compra actualizada correctamente',
        }).then(async ()=>{
          await purchasesUIProps.loadPurchases();
          (onHide as () => void)();
        })
        
      } catch (error) {
        console.log('error', error)
        purchasesUIProps.setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: 'Error al actualizar compra',
        }).then(()=>{
          purchasesUIProps.loadPurchases()
        })
      } 
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={()=>{
        purchasesUIProps.setSelectedPurchase(undefined);
        (onHide as () => void)();
      }}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <PurchaseEditDialogHeader id={(id as number)} />
      {
        id && (purchasesUIProps.selectedPurchase as PurchaseModel)?.id && (
          <PurchaseEditForm
            savePurchase={savePurchase}
            actionsLoading={purchasesUIProps.loadPurchase}
            purchase={(purchasesUIProps.selectedPurchase as PurchaseModel) || purchasesUIProps.initPurchase}
            onHide={onHide}
            paymentProviders={purchasesUIProps.paymentProviders}
          />
        )
      }
      {
        !id && 
          <PurchaseEditForm
            savePurchase={savePurchase}
            actionsLoading={purchasesUIProps.loadPurchase}
            purchase={(purchasesUIProps.selectedPurchase as PurchaseModel) || purchasesUIProps.initPurchase}
            onHide={onHide}
            paymentProviders={purchasesUIProps.paymentProviders}
          />
      }
    </Modal>
  );
}


