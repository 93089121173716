import React, { forwardRef, useEffect, useState } from "react";
import clsx from "clsx";
import {isFragment} from "react-is";

//@ts-ignore
export const CardHeaderIcon = forwardRef(({ className }, ref) => (
    //@ts-ignore
  <span ref={ref} className={clsx("card-head-icon", className)} />
));

//@ts-ignore
// type CardHeaderTitle = {
//     children: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string,
// }
export const CardHeaderTitle: React.FC<CardHeaderProps> = forwardRef(({ className, ...props }, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  //@ts-ignore
  <h3 {...props} ref={ref} className={clsx("card-label", className)}>{""}</h3>
));

type CardHeaderToolbarProps = {
  className?: string,
  props?:{},
}
export const CardHeaderToolbar: React.FC<CardHeaderToolbarProps> = forwardRef(
    //@ts-ignore
  ({ children, className, ...props }, ref) => (
      //@ts-ignore
    <div {...props} ref={ref} className={clsx("card-toolbar", className)}>
      {children}
    </div>
  )
);

type CardHeaderProps = {
    icon?:string,
    title?:React.ReactElement<any, string | React.JSXElementConstructor<any>> | string,
    toolbar?:any,
    sticky?: boolean,
    labelRef?: any,
    className?: string,
}
export const CardHeader:React.FC<CardHeaderProps>  = forwardRef(
  (
      
    {
      children,
      icon,
      title,
      toolbar,
      className,
      sticky = false,
      labelRef,
      ...props
    },
    ref
  ) => {
    const [top, setTop] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);

    useEffect(() => {
      handleResize();

      function handleResize() {
        setWindowHeight(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    });

    useEffect(() => {
      // Skip if sticky is disabled or on initial render when we don't know about window height.
      if (!sticky || windowHeight === 0) {
        return;
      }

      const headerElement = document.querySelector(".header");
      const subheaderElement = document.querySelector(".subheader");
      const headerMobileElement = document.querySelector(".header-mobile");

      let nextMarginTop = 0;

      // mobile header
      if (window.getComputedStyle((headerElement as Element)).height === "0px") {
          //@ts-ignore
        nextMarginTop = (headerMobileElement as Element).offsetHeight;
      } else {
        // desktop header
        if (document.body.classList.contains("header-minimize-topbar")) {
          // hardcoded minimized header height
          nextMarginTop = 60;
        } else {
          // normal fixed header
          if (document.body.classList.contains("header-fixed")) {
              //@ts-ignore
            nextMarginTop += headerElement.offsetHeight;
          }

          if (document.body.classList.contains("subheader-fixed")) {
              //@ts-ignore
            nextMarginTop += subheaderElement.offsetHeight;
          }
        }
      }

      setTop(nextMarginTop);
    }, [sticky, windowHeight]);

    return (
      <div
      //@ts-ignore
        ref={ref}
        className="card-header"
        style={
          !sticky
            ? undefined
            : { top, position: "sticky", backgroundColor: "#fff" }
        }
      >
        {title !== null && (
          <div ref={labelRef} className={clsx("card-title", className)}>
            {icon}

            {/* Wrap string and fragments in CardHeaderTitle */
            typeof title === "string" || isFragment(title) ? (
                //@ts-ignore
              <CardHeaderTitle>{title}</CardHeaderTitle>
            ) : (
              title
            )}
          </div>
        )}

        {toolbar}
        {children}
      </div>
    );
  }
);

type CardBodyProps = {
    fit?: boolean,
    fluid?: boolean, 
    className?: string,
}
export const CardBody: React.FC<CardBodyProps> = forwardRef(
  ({ fit, fluid, className, ...props }, ref) => (
    <div
      {...props}
      //@ts-ignore
      ref={ref}
      className={clsx(
        "card-body",
        {
          "card-body-fit": fit,
          "card-body-fluid": fluid
        },
        className
      )}
    />
  )
);

// type CardFooterProps = {
//     className: string,
// }

export const CardFooter: React.FC<CardBodyProps> = forwardRef(({ className, ...props }, ref) => (
  //@ts-ignore
  <div {...props} ref={ref} className={clsx("card-footer", className)} />
));

type CardProps = {
    fluidHeight?: boolean,
    className?: string,
}

export const Card: React.FC<CardProps> = forwardRef(({ fluidHeight, className, ...props }, ref) => (
  <div
    {...props}
    //@ts-ignore
    ref={ref}
    className={clsx(
      "card card-custom gutter-b",
      { "card-height-fluid": fluidHeight },
      className
    )}
  />
));

// Set display names for debugging.
if (process.env.NODE_ENV !== "production") {
  Card.displayName = "Card";

  CardHeader.displayName = "CardHeader";
  CardHeaderIcon.displayName = "CardHeaderIcon";
  CardHeaderTitle.displayName = "CardHeaderTitle";
  CardHeaderToolbar.displayName = "CardHeaderToolbar";

  CardBody.displayName = "CardBody";
  CardFooter.displayName = "CardFooter";
}
