import jsPDF from 'jspdf'
import moment from 'moment'
import 'moment/locale/es';
import autoTable, { CellInput, RowInput} from 'jspdf-autotable'
// import { toAbsoluteUrl } from '../../_metronic/helpers/AssetHelpers';
// import { ProductRealData } from '../../app/modules/admin/pages/products/models'
// import { OrderModel } from '../../app/modules/processes/pages/orders/models'
import DeleteZerosHelper from '../functions/DeleteZerosHelper';
import { BillingModel, AvailableProduct } from '../../app/modules/processes/pages/billing/models/index';


export interface totalsTable{
  totalProducts: number;
  totalToPay: number;
}

const prepareData = (products: Partial<AvailableProduct>[], format:(value: number | bigint) => string) => {
  let data: RowInput[] = [];
  let aux: CellInput[] = [];

  const groupedByReferenceProducts: Partial<AvailableProduct>[] = products.reduce(
    (prev: Partial<AvailableProduct>[], curr: Partial<AvailableProduct>) => {
      const product = prev.find((p) => p.productName === curr.productName)
      if (product === undefined) return [...prev, {...curr}]
      product.availableQuantity = (product.availableQuantity ?? 0) + (curr.availableQuantity ?? 0)
      product.requestedQuantity = (product.requestedQuantity ?? 0) + (curr.requestedQuantity ?? 0)
      product.restQuantity = (product.restQuantity ?? 0) + (curr.restQuantity ?? 0)

      return prev
    },
    []
  )

  groupedByReferenceProducts.forEach((product,i:number) => {
    aux[0] = i+1;
    aux[1] =  product.productName ? product.productName : '';
    aux[2] = DeleteZerosHelper(format(product.price as number));
    aux[3] = product.requestedQuantity as number;
    aux[4] = DeleteZerosHelper(format(Number(product.price) * Number(product.requestedQuantity)));
    data.push(aux);
    aux = [];
  })
  return data;
}

const calcTotals = (products:Partial<AvailableProduct>[]) => {
  let result: totalsTable = {
    totalProducts: 0,
    totalToPay: 0
  }
  let totalProducts = 0;
  let totalToPay = 0;
  products.forEach(product => {
    totalProducts += Number(product.requestedQuantity);
    totalToPay += Number(product.price) * Number(product.requestedQuantity);
  })
  result.totalProducts = totalProducts;
  result.totalToPay = totalToPay;
  return result;
}


export async function generateSalesOrder(order: Partial<BillingModel>, format:(value: number | bigint) => string): Promise<void>{
  const head = [["#","Nombre del producto","Costo","Cantidad","Sub Total"]]
  const dataFixed = prepareData(JSON.parse(order?.options as string) ,format);
  const totales = calcTotals(JSON.parse(order?.options as string));
  dataFixed.push(["","","Total",`${totales.totalProducts}`,`${DeleteZerosHelper(format(totales.totalToPay))}`])
  dataFixed.push(["","","","Descuento",`${DeleteZerosHelper(format(order.discount ? order.discount : 0))}`])
  dataFixed.push(["","","","Cantidad Total",`${DeleteZerosHelper(format(totales.totalToPay - (order.discount ? order.discount: 0 )))}`])


  const doc = new jsPDF();
  //*Titulo
  doc.setFont("Helvetica-Bold");
  doc.setFontSize(24);
  doc.text("Informe de venta del producto",10,20);

  //*Subtitulo
  doc.setFont("Helvetica-Oblique");
  doc.setFontSize(12);
  //Fecha de venta
  doc.text(`Fecha de Venta: ${moment(order.billingDate).format('ll')}`,10,30);

  //No. Referencia
  doc.text(`Referencia No: ${order.reference}`,10,35);

  //Clientes
  doc.text(`Clientes: ${order.clientName}`,10,40);

  //Notas
  const notas = doc.splitTextToSize(`Notas: ${order.description}`, doc.internal.pageSize.width - 25 );
  doc.text(notas, 10, 45);
  const notasY = (notas.length - 1) * 5

  //Lista de productos
  doc.setFontSize(14)
  doc.text("Lista de productos", 10, 55 + notasY);

  //*Tabla
  autoTable(doc, {
    head: head,
    body: dataFixed,
    startY: 60 + notasY,
    margin: {
      left: 10
    }
  });



  let finalY = (doc as any).lastAutoTable.finalY
  //*Nombre Fabrica
  doc.setFontSize(18)
  doc.text(`${order.factoryName}`,15,finalY+20)

  doc.save(`${order.reference}.pdf`)
}
