import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useWalletUIContext, WalletUIContextModel} from '../WalletUIContext';
import { useIntl } from "react-intl";
import useDebounceFnc from "../../../../../../utils/customHooks/useDebounceFnc";

interface filterInterface {
  type: number | undefined;
  name: string;
  clientFactory: number | undefined;
}
interface filterValues {
  type: string;
  searchText: string;
  clientFactory: string;
}
const prepareFilter = (queryParams: any, values : filterValues) => {
  const { type, searchText, clientFactory } = values;

  const newQueryParams = { ...queryParams };
  const filter : filterInterface = {
    type: undefined,
    name: '',
    clientFactory: undefined
  };
  // Filter by type
  filter.type = type !== "" ? +type : undefined;
  filter.clientFactory = clientFactory !== "" ? +clientFactory : undefined;

  // Filtrar por cliente,fabrica o referencia
  if (searchText) {
    filter.name = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export interface LoansFilterProp {
  listLoading: boolean
}
export const WalletFilter: React.FC<{}>=() => {
  // Loans UI Context
  const intl = useIntl();
  const walletUIContext = (useWalletUIContext() as WalletUIContextModel);
  const walletUIProps = useMemo(() => {
    return {
      queryParams: walletUIContext.queryParams,
      setQueryParams: walletUIContext.setQueryParams,
    };
  }, [walletUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = useDebounceFnc((values:any) => {
    const newQueryParams = prepareFilter(walletUIProps.queryParams, values);
    if (!isEqual(newQueryParams, walletUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      walletUIProps.setQueryParams(newQueryParams);
    }
  }, 400);

  return (
    <>
      <Formik
        initialValues={{
          type: "", 
          searchText: "",
          clientFactory: ""
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
            <form onSubmit={handleSubmit} className="form form-label-right">
                <div className="form-group row">
                    <div className="col-lg-3">
                        <select
                            className="form-control"
                            name="clientFactory"
                            placeholder={intl.formatMessage({
                                id: "LABELS.FILTER.FILTER_BY_DEBTOR_TYPE",
                            })}
                            onChange={(e) => {
                                setFieldValue("clientFactory", e.target.value);
                                handleSubmit();
                            }}
                            onBlur={handleBlur}
                            value={values.clientFactory}
                        >
                            <option value="">{intl.formatMessage({id:"LABELS.FILTER.ALL"})}</option>
                            <option value="67">
                                {intl.formatMessage({ id: "CLIENT.CLIENT" })}
                            </option>
                            <option value="70">
                                {intl.formatMessage({ id: "FACTORY.FACTORY" })}
                            </option>
                        </select>
                        <small className="form-text text-muted">
                        Filtrar por cliente o fábrica
                        </small>
                    </div>
                    <div className="col-lg-3">
                        <input
                        type="text"
                        className="form-control"
                        name="searchText"
                        placeholder={intl.formatMessage({id:"LABELS.FILTER.SEARCH"})}
                        onBlur={handleBlur}
                        value={values.searchText}
                        onChange={(e) => {
                            setFieldValue("searchText", e.target.value);
                            handleSubmit();
                        }}
                        />
                        <small className="form-text text-muted">
                            Filtrar por nombre
                        </small>
                    </div>
                </div>
            </form>
        )} 
      </Formik>
    </>
  );
}



