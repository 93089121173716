import React,{useState, useEffect} from 'react'
import { ClientModel } from '../../../../../admin/pages/client/models/index';
import { FactoryModel } from '../../../../../admin/pages/factories/models/index';
import axios from 'axios';

const useLoadForm = () => {
    const [clients, setClients] = useState<ClientModel[]>();
    const [factories, setFactories] = useState<FactoryModel[]>();

    useEffect(() => {
        const loadFactory = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/factory`);
            setFactories(response.data);
        }
        const loadClients = async() => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/client`);
            setClients(response.data)
        }
        loadClients();
        loadFactory();
    },[])

    return {
        clients,
        factories,
    }
}

export default useLoadForm