/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
// import AsideSectionTitle from './AsideSectionTitle'
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../../setup'
import { UserComplete, UserMenu } from '../../../../app/modules/auth/models/UserModel';

export function AsideMenuMain() {
  const user: UserComplete = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserComplete
  const intl = useIntl()
  const drawMenu = (data: UserMenu[] )=> {
    return (
      <>
        {
          data.map((item, index)=> {
            if(item.children?.length === 0){
              return <AsideMenuItem
                title={intl.formatMessage({id:item.actionTitle})}
                icon={item.actionIcon}
                to={(item.UrlMenu as string)}
                key={index}
                hasBullet={item.actionHasBullet}
              />
            } else {
              return <AsideMenuItemWithSub
                title={intl.formatMessage({id:item.actionTitle})}
                to={(item.UrlMenu as string)}
                hasBullet={item.actionHasBullet}
                icon={item.actionIcon}
                key={index}
              >
                {drawMenu((item.children as UserMenu[]))}
              </AsideMenuItemWithSub>
            }
          })
        }
      </>
    )
  }
  return (
    <>
      {/* <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div> */}
      {/* <AsideSectionTitle
        title='First Section'
      /> */}
      {drawMenu(user.menu.filter(m => m.segmentId === 2))}
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
