import React, { useMemo, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../../_metronic/partials/control";
import TextField from '@mui/material/TextField';
import AutoComplete from '@mui/material/Autocomplete';
import { useInventoryUIContext, InventoryUIContextModel } from "./InventoryUIContext"
import { FactoryModel } from '../../../admin/pages/factories/models/index';
import { useIntl } from 'react-intl';
import { Table } from 'antd';

export function InventoryCard(){
    const intl = useIntl();
    const inventoryUIContext = (useInventoryUIContext() as InventoryUIContextModel);
    const inventoryUIProps = useMemo(() => {
        return{
            factories: inventoryUIContext.factories,
            selectedFactory: inventoryUIContext.selectedFactory,
            setSelectedFactory: inventoryUIContext.setSelectedFactory,
            fetchInventory: inventoryUIContext.fetchInventory,
            inventory: inventoryUIContext.inventory
        }
    },[inventoryUIContext])

    const columns = [
        {
          title: 'Insumo',
          dataIndex: 'supplyName',
          key: 'supplyName',
        },
        {
            title: 'Cantidad actual',
            dataIndex: 'currentQuantity',
            key: 'currentQuantity',
        },
        {
          title: 'Cantidad comprada',
          dataIndex: 'purchasedSupp',
          key: 'purchasedSupp',
        },
        {
            title: 'Cantidad utilizada',
            dataIndex: 'usedSupp',
            key: 'usedSupp',
        },
        {
          title: 'Unidad',
          dataIndex: 'unidad',
          key: 'unidad',
        }
    ];


    return(
        <Card>
            <CardHeader title="Inventario">
                <CardHeaderToolbar>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <div className="form-group row mb-7">
                    <div className="col-lg-10">
                        <b>
                            Escoja una fábrica
                        </b>
                        <AutoComplete
                            disablePortal
                            id="combo-box-demo"
                            className="mt-5"
                            options={inventoryUIProps.factories || []}
                            sx={{ width: 500 }}
                            onChange={(e:any,value:FactoryModel | null) => {
                                inventoryUIProps.setSelectedFactory(Number(value !== null ? value.id : 0))
                                inventoryUIProps.fetchInventory(Number(value !== null ? value.id : 0))
                            }}
                            getOptionLabel={(option:FactoryModel) => `${option.name}`}
                            renderInput={(params) => <TextField {...params} label={"Fabricas"} />}
                        />
                    </div>
                </div>

                {inventoryUIProps.selectedFactory !== 0 &&
                    <div className="form-group row mb-7">
                        <div className="col-lg-12">
                            <Table
                                columns={columns} dataSource={inventoryUIProps.inventory} pagination={{
                                    pageSizeOptions: [10, 50,100,500,1000, inventoryUIProps.inventory?.length ?? 0],
                                    showSizeChanger: true,
                                    defaultPageSize: 50,
                                }}
                            />
                        </div>
                    </div>
                }
            </CardBody>
        </Card>
    )
}
