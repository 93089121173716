import React, {createContext, useContext, useState, useCallback, useEffect} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter, initialValues} from "./ProductStocksUIHelpers";
import axios from "axios";
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../../../setup/redux/RootReducer';
import { ProductStockModel } from "./models";
import { UserComplete } from '../../../../../../../base_project_backend/src/api/interfaces/usuarios.interface';
import useIntlCurrencyFormat from '../../../../../utils/customHooks/useIntlCurrencyFormat';
import DeleteZerosHelper from '../../../../../utils/functions/DeleteZerosHelper';

//Modelos
import { FactoryModel } from '../../../admin/pages/factories/models';
import { ClientModel } from '../../../admin/pages/client/models/index';

interface dataDataResultTableProductStocks {
  entities: any[],
  totalCount: number,
  errorMessage: string,
}
export interface resultTableProductStocks {
  data: dataDataResultTableProductStocks
  totalCount: number,
}
export interface ProductStocksUIContextModel {
  queryParams:any,
  setQueryParamsBase: (queryParams:any) => void,
  ids: number[],
  setIds: (ids: number[]) => void,
  setQueryParams: (queryParams:any) => void,
  initProductStock:Partial<ProductStockModel>,
  newProductStockButtonClick: any,
  openEditProductStockDialog: any,
  productStockButtonClick: any,
  openDeleteProductStockDialog: any,
  openDeleteProductStocksDialog: any,
  openFetchProductStocksDialog: any,
  openUpdateProductStocksStatusDialog: any,
  valuesTable: resultTableProductStocks,
  tableLoading: boolean,
  // setTableLoading: (tableLoading: boolean) => void,
  fetchProductStock: (id: number) => Promise<void>
  loadProductStocks: () => Promise<void>
  loadProductStock: boolean,
  selectedProductStock:ProductStockModel | undefined
  setSelectedProductStock:  React.Dispatch<React.SetStateAction<ProductStockModel | undefined>>,
  factories: FactoryModel[],
  clients: ClientModel[]
  isLoading: boolean;
  setIsLoading: (isLoading:boolean) => void;
  factoryId: number;
  setFactoryId: (factoryId:number)=>void;
}
const ProductStocksUIContext = createContext<ProductStocksUIContextModel | null>(null);

export function useProductStocksUIContext() {
  return useContext(ProductStocksUIContext);
}

export const ProductStocksUIConsumer = ProductStocksUIContext.Consumer;

export type ProductStocksUIProviderProps = {
  productStocksUIEvents: any,
}

export const ProductStocksUIProvider:React.FC<ProductStocksUIProviderProps> =  ({productStocksUIEvents, children}) => {
  const user: UserComplete = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserComplete;
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [valuesTable, setValuesTable] = useState<resultTableProductStocks>(initialValues);
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedProductStock, setSelectedProductStock] = useState<ProductStockModel>();
  const [loadProductStock, setLoadProductStock] = useState(false);
  const [ids, setIds] = useState<number[]>([]);
  const {cop} = useIntlCurrencyFormat();

  const [factories,setFactories] = useState<FactoryModel[]>([]);
  const [clients,setClients] = useState<ClientModel[]>([]);
  const [isLoading,setIsLoading] = useState(false);

  const [factoryId, setFactoryId] = useState(0);



  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {

      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initProductStock: Partial<ProductStockModel> = {
    id: undefined,
    factoryId: 0,
    clientId: 0,
    suggestedPrice: 0,
    price: 0,
    quantity: 0,
    discount: 0,
    reference: '',
  };

  /**
   * @description Obtener las ordenes de venta de una empresa
   */
  const loadProductStocks = async () => {
    setSelectedProductStock(undefined);
    setTableLoading(true);
    try {
      const productStockResult = await axios.post(`${process.env.REACT_APP_API_URL}/product/getTableProducts`,{
        queryParams,
        companyId: user.company.id,
      });
      (productStockResult.data as unknown as resultTableProductStocks).data.entities.map((e:any) => {
        e.totalTotal = DeleteZerosHelper(cop?.format(e.total - e.discount));
        e.discount = DeleteZerosHelper(cop?.format(e.discount))
        e.total = DeleteZerosHelper(cop?.format(e.total));
      })
      setValuesTable(productStockResult.data);
    } catch (error) {
      console.log('error', error)
    } finally {
      setTableLoading(false);
    }
  }


  const loadFactories = async ()=>{
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/factory`);
      setFactories(response.data);
    } catch (error) {
    } finally {

    }
  }

  const loadClients = async ()=>{
    try {
        const result = await axios.get(`${process.env.REACT_APP_API_URL}/client`);
        setClients(result.data);
    } catch (error) {
        console.log('error', error)
    } finally {}
  }


  useEffect(()=> {
    loadFactories();
    loadClients();
  },[])
  useEffect(()=> {
    loadProductStocks();
  },[queryParams])



  const fetchProductStock = useCallback(async (id:number)=> {
    try {
      setLoadProductStock(true)
      const result = await axios.get(`${process.env.REACT_APP_API_URL}/product/${id}`);
      setSelectedProductStock(result.data);
      setFactoryId(result.data.factory);
    } catch (error) {
      console.log('error loading productStock', error)
    } finally {
      setTimeout(()=>{
        setLoadProductStock(false);
      },1500)
    }
  },[])

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initProductStock,
    newProductStockButtonClick: ()=>{
      setSelectedProductStock(undefined);
      productStocksUIEvents.newProductStockButtonClick();
    },
    openEditProductStockDialog: productStocksUIEvents.openEditProductStockDialog,
    openDeleteProductStockDialog: productStocksUIEvents.openDeleteProductStockDialog,
    productStockButtonClick: productStocksUIEvents.productStockButtonClick,
    openDeleteProductStocksDialog: productStocksUIEvents.openDeleteProductStocksDialog,
    openFetchProductStocksDialog: productStocksUIEvents.openFetchProductStocksDialog,
    openUpdateProductStocksStatusDialog: productStocksUIEvents.openUpdateProductStocksStatusDialog,
    valuesTable,
    tableLoading,
    loadProductStocks,
    fetchProductStock,
    selectedProductStock,
    setSelectedProductStock,
    loadProductStock,
    factories,
    clients,
    isLoading,
    setIsLoading,
    factoryId,
    setFactoryId
  };

  return <ProductStocksUIContext.Provider value={value}>{children}</ProductStocksUIContext.Provider>;
}


