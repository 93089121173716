import axios from 'axios';
import React, {useState, useEffect} from 'react'
import { FactoryModel } from '../../../../../admin/pages/factories/models';
import { SupplyInterface, PurchaseRealData } from '../../purchase-edit-dialog/PurchaseEditForm';
import { ProviderModel } from '../../../../../admin/pages/providers/models/index';
import { getDifference } from '../../../../../../../utils/differenceObjArr';
import { SupplyRealData } from '../../../../../admin/pages/supplies/models/index';
import { IndexAux } from '../../../../../../../utils/interfaces';
import { PurchaseModel } from '../../models/index';
import { FormikProps } from 'formik';

interface LoadFormInterface {
  purchase:Partial<PurchaseModel> | undefined,
  setAddSupply: React.Dispatch<React.SetStateAction<PurchaseRealData[]>>,
  formRef: React.RefObject<FormikProps<PurchaseModel>>,
}
const useLoadForms = ({
  purchase,
  setAddSupply,
  formRef,
}:LoadFormInterface) => {
    const [supplyTypeAux, setSupplyTypeAux] = useState<SupplyInterface[]>([]);
    const [factories, setFactories] = useState<FactoryModel[]>([]);
    const [providers, setProviders] = useState<ProviderModel[]>([]);
    const [supplyType, setSupplyType] = useState<SupplyInterface[]>([]);

    useEffect(() => {
        const loadSuppliesByCompany = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/supply`);
            setSupplyType(response.data);
            setSupplyTypeAux(response.data);
            if(purchase?.suppliesId){
              const aux: PurchaseRealData[] = [];
              const indexAux: IndexAux[] = [];
              (purchase?.supplies as SupplyRealData[]).map((supply:SupplyRealData)=>{
                (aux as PurchaseRealData[]).push({
                  supplyTypeId: Number(`${supply.supplyTypeId}${supply.unityId}`),
                  price: supply.price,
                  quantity: supply.quantity,
                  supplyDescription: supply.supplyDescription,
                  unity: supply.unity,
                  id: Number(supply.supplyTypeId),
									isSelected: supply.isSelected,
                })
                indexAux.push({
                  id: Number(supply.supplyTypeId)
                });
              })
              setAddSupply(aux);
              setSupplyType(getDifference(response.data,indexAux))
              formRef.current?.setFieldValue('values', JSON.stringify(aux));
            }
          } catch (error) {
            console.log('error loading supplies', error)        
          } finally {}
        }
        const loadProvider = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider?status=1`);
            setProviders(response.data);
          } catch (error) {
            console.log('error loading provider', error)        
          } finally {}
        }
        const loadFactories = async ()=>{
          try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/factory`);
            setFactories(response.data);
          } catch (error) {
          } finally {
    
          }
        }
        loadSuppliesByCompany();
        loadProvider();
        loadFactories();
      },[])
      
    return {
      supplyTypeAux,
      factories,
      providers,
      supplyType,
      setSupplyType,

    }
}

export default useLoadForms
