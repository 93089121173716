
import React,{ useState } from 'react'
import { FactoryModel } from '../../../../admin/pages/factories/models/index';
import { ClientModel } from '../../../../admin/pages/client/models/index';
import { Field } from 'formik';
import { Input } from '../../../../../../_metronic/partials/control/forms/Input';
import { useIntl } from 'react-intl';
import TextAreaInput from '../../../../../../_metronic/partials/control/forms/TextAreaInput';
import { LoanModel } from '../models/index';
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat';
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper';
import { reverse } from 'dns';
import { reverseFormatNumber } from '../../../../../../utils/index';
import { DatePicker } from 'antd';
import moment from 'moment';
interface DebtorDetailProps {
    debtor: ClientModel | FactoryModel | null
    value:LoanModel
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    loan: Partial<LoanModel> | undefined
}

const DebtorDetail: React.FC<DebtorDetailProps> = ({
    debtor,
    value,
    setFieldValue,
    loan,
}) => {
    const intl = useIntl()
    const {cop} = useIntlCurrencyFormat();
    const [loanDate, setLoanDate] = useState<moment.Moment | null>(moment(new Date()));
    return (
        <>
            {/* INFORMACION DEL DEUDOR */}
           {debtor !== null && <div className="form-group row my-7">
                {/* Document */}
                <div className="col-lg-4">
                <Field
                    component={Input}
                    disabled
                    label={intl.formatMessage({ id: "GENERAL.NAME"})}
                    value={debtor.name}
                />
                </div>
                {/* Departamento */}
                <div className="col-lg-4 mt-7 mt-lg-0">
                <Field
                    type="text"
                    component={Input}
                    disabled
                    value={debtor.email1}
                    label={intl.formatMessage({ id: "GENERAL.EMAIL"})}
                />
                </div>
                {/* Municipio */}
                <div className="col-lg-4 mt-7 mt-lg-0">
                <Field
                    type="text"
                    component={Input}
                    label={intl.formatMessage({ id: "GENERAL.PHONE"})}
                    disabled
                    value={debtor.phone1}
                />
                </div>
            </div>}

            {/* INFORMACION SOBRE EL PRESTAMO */}
            <div className="form-group row my-7">
                <div className="col-lg-6">
                    <Field
                        name="value"
                        component={Input}
                        disabled={loan?.id}
                        value={loan?.id && DeleteZerosHelper(cop.format(loan.value as number))}
                        // onChange={(e:any)=>{
                        //     const val = e.target.value
                        //     const realValue = parseFloat(reverseFormatNumber(val, 'en'))
                        //     setFieldValue("value", realValue)
                        // }}
                        // @ts-ignore
                        // value={cop?.format(value.value)}
                        placeholder={"Valor a prestar"}
                        label={"Valor a prestar"}
                    />
                </div>
                <div className="col-lg-6">
                    <label>
                    <b>
                        {intl.formatMessage({id: "LOAN.DATE"})}
                    </b>
                    </label>
                    <DatePicker
                        name="loanDate"
                        disabled={loan?.id ? true : false}
                        size={"large"}
                        // @ts-ignore
                        value={loan?.id ? moment(loan?.loanDate) : loanDate}
                        placeholder={intl.formatMessage({id:"GENERAL.CHOOSE_DATE"})}
                        style={{width:"100%"}}
                        onChange={(date: any | null, dateString:string):void =>{
                            setLoanDate(date)
                            setFieldValue("loanDate",date)
                        }}
                    />
                </div>
            </div>
            <div className="form-group row my-7">
                <div className="col-lg-12">
                    <Field
                        disabled={loan?.id}
                        name="description"
                        component={TextAreaInput}
                        placeholder={intl.formatMessage({ id: "GENERAL.DESCRIPTION"})}
                        label={intl.formatMessage({ id: "GENERAL.DESCRIPTION"})}
                    />
                </div>
            </div>
        </>
    )
}

export default DebtorDetail