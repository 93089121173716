import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/partials/control";
import { OrdersFilter } from "./orders-filter/OrdersFilter";
import { OrdersTable } from "./orders-table/OrdersTable";
// import { OrdersGrouping } from "./orders-grouping/OrdersGrouping";
import { useOrdersUIContext, OrdersUIContextModel } from './OrdersUIContext';
import { useIntl } from "react-intl";
import { PreloadingPage } from "../../../../jsxcomponents/PreloadingPage";

export function OrdersCard() {
  const intl = useIntl();
  const ordersUIContext = (useOrdersUIContext() as OrdersUIContextModel);
  const ordersUIProps = useMemo(() => {
    return {
      ids: ordersUIContext.ids,
      newOrderButtonClick: ordersUIContext.newOrderButtonClick,
      productStockButtonClick: ordersUIContext.productStockButtonClick,
      isLoading: ordersUIContext.isLoading
    };
  }, [ordersUIContext]);

  return (
    <Card>
      <PreloadingPage
        isVisible={ordersUIProps.isLoading}
      />
      <CardHeader title={intl.formatMessage({id: "ORDER.ORDERS"})}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-info mx-4"
            onClick={ordersUIProps.productStockButtonClick}
          >
            {intl.formatMessage({id: "PRODUCT-STOCK.PRODUCT-STOCK"})}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={ordersUIProps.newOrderButtonClick}
          >
            {intl.formatMessage({id: "ORDER.NEW_ORDER"})}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <OrdersFilter />
        {/* {ordersUIProps.ids.length > 0 && <OrdersGrouping />} */}
        <OrdersTable />
      </CardBody>
    </Card>
  );
}



