import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { getNewAccessToken } from '../../utils/refreshingToken';

export default function setupAxios(axios: any, store: any) {
  const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const {auth :{accessToken}} = store.getState();
    //@ts-ignore
    if(accessToken && !config._retry) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  }
  
  const onResponse = async(response: AxiosResponse): Promise<AxiosResponse> => {
    return response;
  }
  const onErrorRequest = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

  const onErrorResponse = async(error: AxiosError): Promise<AxiosError> => {
    const originalRequest = error.config;
    //@ts-ignore
    if(error.response?.status === 401 && !originalRequest._retry && !error?.response.data._retry ){
      //@ts-ignore
      originalRequest._retry = true;
      const {auth:{refreshToken}} = store.getState();
      const accessToken = await getNewAccessToken(refreshToken, store.dispatch);
      originalRequest.headers.Authorization = `Bearer ${accessToken}`;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }

  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(onRequest,onErrorRequest);
  axios.interceptors.response.use(onResponse,onErrorResponse);
}
