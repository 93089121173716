// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, {useEffect, useState} from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input, Select,
} from "../../../../../../_metronic/partials/control";
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from 'react-redux';
import axios from 'axios';
import { BillingModel } from "../models";
import { UserComplete } from "../../../../auth/models/UserModel";
import { RootState } from '../../../../../../setup/redux/RootReducer';
import { BillingsLoadingDialog } from '../billings-loading-dialog/BillingsLoadingDialog';
import useLoadForm from "../components/hooks/useLoadForm";
import { AutoCompleteInput } from "../../../../../../_metronic/partials/control/forms/AutoComplete";
import { OrderModel } from '../../orders/models/index';
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat';
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper';
import { ClientModel } from '../../../../admin/pages/client/models/index';
import { FactoryModel } from '../../../../admin/pages/factories/models/index';
import Swal from 'sweetalert2';
import { DatePicker } from "antd";
import moment from 'moment';
import { ProductModel } from '../../../../admin/pages/products/models/index';
import ProductTable from "../components/ProductTable";
import { AvailableProduct } from '../models/index';
import { validateBillingForm, submitValidationForm } from '../components/validationForm';
import lodash from 'lodash';
import { generateSalesOrder } from "../../../../../../utils/PDF/SalesOrder";
import TextAreaInput from '../../../../../../_metronic/partials/control/forms/TextAreaInput';
export interface BillingEditFormProps {
  saveBilling: (billing: any) => void;
  billing?: Partial<BillingModel>
  actionsLoading?: boolean;
  onHide?: () => void;
  id?:number
}

interface BillingValueProps {
  value: number
  price: number
  name: string
  quantityBilled: number
  id: number
}
export const BillingEditForm: React.FC<BillingEditFormProps> = ({
  saveBilling,
  billing,
  actionsLoading,
  onHide,
  id,
}) => {
  const userComplete: UserComplete = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserComplete
  const [documentType, setDocumentType] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState<OrderModel>();
  const [selectedProduct, setSelectedProduct] = useState<ProductModel | null>(null);
  const [availableProduct, setAvailableProduct] = useState<AvailableProduct[]>([])
  const [selectedClient, setSelectedClient] = useState<ClientModel>();
  const [selectedFactory, setSelectedFactory] = useState<FactoryModel>();
  const [billingValues, setBillingValue] = useState<BillingValueProps[]>([]);
  const [discount, setDiscount] = useState<number>(0)
  const [quantityValues, setQuantityValues] = useState<any[]>([]);
  const [totalBilled, setTotalBilled] = useState(0);
  const {cop} = useIntlCurrencyFormat();
  const intl = useIntl();
  const [billingDate,setBillingDate] = useState<moment.Moment | null>(moment(new Date()));
  const [remaining,setRemaining] = useState<number>(0);
  const [tempValue, setTempValue] = useState<number>(0);


  // Validation schema
  const BillingEditSchema = Yup.object().shape({
  });
  const {
    factory,
    loadProductByFactory,
    product,
    auxProducts,
    clients,
    setProduct,
    lastBilled,
    setLastBilled,
  } = useLoadForm();

  const productReferenceSelected: ProductModel | null = React.useMemo(
    () =>
      (product ?? [])
        ?.filter((p) => p.name === selectedProduct?.name)
        .reduce((prev: ProductModel | null, curr) => {
          if (prev === null) return { ...curr };
          prev.quantity = (prev.quantity ?? 0) + (curr.quantity ?? 0)
          prev.suggestedPrice =
            Number(prev.suggestedPrice) <= Number(curr.suggestedPrice) ? curr.suggestedPrice : prev.suggestedPrice
          return { ...prev }
        }, null),
    [selectedProduct, product, selectedFactory]
  )

  useEffect(() => {
    const loadDocumentTypes = async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/documentType`);
      setDocumentType(response.data);
    }
    loadDocumentTypes();
  },[])

  useEffect(()=>{
    let cont = 0
    billingValues.map((b)=> {
      cont += Number(b.value)
    })
    setTotalBilled(cont)
  },[billingValues])

  useEffect(()=>{
    if (id){
      // @ts-ignore
      const auxPrd = JSON.parse(billing?.options);
      setBillingValue(auxPrd);
      // @ts-ignore
      setSelectedOrder({...selectedOrder,products:auxPrd})
    }
  },[])

  useEffect(() => {
    if(selectedOrder){
      setRemaining(selectedOrder?.total -  selectedOrder?.billed - Number(selectedOrder?.discount))
    }
  },[selectedOrder])

  // ? functions handle events

  const onAddProduct = async (values:BillingModel, setFieldValue:(field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
    const selectedProducts = product?.filter((x) => x.name === selectedProduct?.name)
    
    const val = await validateBillingForm(productReferenceSelected, values)
    if (!val) return val
    const aux = availableProduct;
    // ? Agregar producto a la tabla
    let quantity = Number(values.quantity);
    selectedProducts
      ?.sort((a, b) => Number(a.version) - Number(b.version))
      .forEach((x) => {
        const requestedQuantity = quantity <= Number(x.quantity) ? quantity : Number(x.quantity)
        console.log(x.version, requestedQuantity)
        quantity -= requestedQuantity
        aux.push({
          id: x.id as number,
          productName: x.name as string,
          costPrice: x.cost as number,
          suggestedPrice: x.suggestedPrice as number,
          restQuantity: Number(x.quantity) - requestedQuantity,
          availableQuantity: Number(x.quantity),
          price: Number(values.price),
          requestedQuantity,
        })
      })
    setAvailableProduct([...aux])
    // ? Quitar el producto de los productos generales osea del select
    const auxArr = product?.filter(x => x.name !== selectedProduct?.name);
    setProduct([...(auxArr as ProductModel[])]);

    // ? Quitar el selected product
    setSelectedProduct(null)

    //? Borrar los datos
    setFieldValue('price',0)
    setFieldValue('quantity',0)

    // ? Calcular el precio total facturado
    let totalBilledAux = lodash.sum(aux.map(x => x.price * x.requestedQuantity))
    if(discount && !isNaN(discount) && discount > 0) totalBilledAux -= discount
    setFieldValue('total', totalBilledAux)

  }

  const onDeleteProduct = async (
    prd: AvailableProduct,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    // ? Quitar producto de la tabla
    const auxAvailable = availableProduct.filter((x) => x.productName !== prd.productName)
    setAvailableProduct([...auxAvailable])
    // ? Agregar productos al select
    const auxPrd = product
    auxPrd?.push(
      ...((auxProducts ?? []).filter((p) => p.name === prd.productName) as ProductModel[])
    )
    setProduct([...(auxPrd as ProductModel[])])
    // ? Recalcular el precio total facturado
    let totalBilledAux = lodash.sum(auxAvailable.map((x) => x.price * x.requestedQuantity))
    if (discount && !isNaN(discount) && discount > 0) totalBilledAux -= discount
    setFieldValue('total', totalBilledAux)
  }

  const onSubmit = async (values:BillingModel) => {
    const filteredAvailableProduct = availableProduct.filter(p => p.availableQuantity !== 0)
    //antes era totalBilled
    const val = await submitValidationForm({
      values,
      selectedClient,
      availableProduct: filteredAvailableProduct,
      billingDate,
      lastBilled,
    })
    if (!val) return;
    values.reference = lastBilled;
    const vall = {
      ...values,
      billingDate,
      discount,
      options: JSON.stringify(filteredAvailableProduct),
      companyId : userComplete.company.id,
      investorProfit: selectedFactory?.perProductProfit ?? 0,
    }
    Swal.fire({
      icon:"warning",
      showCancelButton: true,
      title: `¿Estás seguro de que deseas facturar al cliente ${selectedClient?.name} por un total de ${DeleteZerosHelper(cop.format(values.total as number))}?`,
    }).then((result) => {
      if(result.value) saveBilling(vall);
    })
  }
  return (
    <>
      {
        actionsLoading ? <BillingsLoadingDialog color={"primary"}/> :
          <Formik
            enableReinitialize={true}
            initialValues={(billing as BillingModel)}
            validationSchema={BillingEditSchema}
            onSubmit={(values) => onSubmit(values)}
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <>
                <Modal.Body className="cursor-default overlay overlay-block ">
                  {actionsLoading && (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-success" />
                    </div>
                  )}
                  <Form className="form form-label-right" onSubmit={handleSubmit}>
                    {/* Nombre y tipo de documento */}
                    {
                      !id &&
                        <div className="form-group row mb-7">
                          <div className="col-lg-4">
                            <Field
                                name="factoryId"
                                component={AutoCompleteInput}
                                className="mt-5"
                                // placeholder={intl.formatMessage({ id: "GENERAL.DOCUMENT"})}
                                label={"Fabricas"}
                                options={factory || []}
                                disabled={id ? true : false}
                                onChange={(e:any, value: FactoryModel | null)=> {
                                  setSelectedFactory(value as FactoryModel)
                                  setFieldValue("factoryId",value?.id)
                                  loadProductByFactory(value?.id)
                                  setAvailableProduct([]);
                                  setFieldValue('total',0)
                                }}
                                getOptionLabel={(option: FactoryModel) => `${option.name}`}
                            />
                          </div>
                          <div className="col-lg-4 mt-7 mt-lg-0">
                            <Field
                                name="clientId"
                                component={AutoCompleteInput}
                                className="mt-5"
                                // placeholder={intl.formatMessage({ id: "GENERAL.DOCUMENT"})}
                                label={"Clientes"}
                                options={clients || []}
                                onChange={(e:any, value: ClientModel | null)=> {
                                  setSelectedClient(value as ClientModel)
                                  setFieldValue("clientId",value?.id)
                                }}
                                getOptionLabel={(option: FactoryModel) => `${option.name}`}
                            />
                          </div>
                          <div className="col-lg-4 mt-7 mt-lg-0">
                              <Field
                                type="text"
                                name="reference"
                                component={Input}
                                value={lastBilled}
                                onChange={ (e:any) => {
                                  setFieldValue('reference', e.target.value)
                                  setLastBilled(e.target.value)
                                }}
                                disabled
                                label={"Referencia"}
                              />
                          </div>
                        </div>

                    }
                    {
                      !id && selectedFactory &&
                        <>
                          {/* Input for products */}
                          <div className="form-group row mb-0">
                            {/* Productos */}
                            <div className="col-lg-4 mt-7 mt-lg-0">
                              <Field
                                name="orderId"
                                component={AutoCompleteInput}
                                className="mt-5"
                                // placeholder={intl.formatMessage({ id: "GENERAL.DOCUMENT"})}
                                label={"Productos"}
                                options={product?.filter(p => p.version === "1") || []}
                                disabled={id ? true : false}
                                value={selectedProduct}
                                onChange={(e:any, value: ProductModel | null)=> {
                                  setSelectedProduct(value as ProductModel)
                                  setFieldValue("productId",value?.id)
                                  const auxArr: BillingValueProps[] = []
                                  const auxVal: any[] = []
                                  // value?.products?.forEach((product)=>{
                                  //   auxArr.push({
                                  //     id: product.productId,
                                  //     price: product.price,
                                  //     name: product.name,
                                  //     quantityBilled: 0,
                                  //     value: Number(product.price) * Number(product.quantity),
                                  //   })
                                  //   auxVal.push({
                                  //     value: product.quantity,
                                  //   })
                                  // })
                                  // setBillingValue(auxArr)
                                  // setQuantityValues(auxVal)
                                  setFieldValue('options', JSON.stringify(auxArr))
                                }}
                                getOptionLabel={(option: ProductModel) => `${option.name}`}
                              />
                            </div>
                            <div className="col-lg-4 mt-7 mt-lg-0">
                              <Field
                                  type="text"
                                  name="quantity"
                                  component={Input}
                                  disabled={billing?.id ? true : false}
                                  //value={cop.format(totalBilled)}
                                  value={ billing?.id ? DeleteZerosHelper(cop?.format(Number(billing.totalBilled))) : values.quantity}
                                  // label={"Total facturación"}
                                  onChange={ (e:any) => {
                                    // setTempValue(Number(e.target.value))
                                    setFieldValue('quantity', e.target.value)
                                  }}
                                  label={"Cantidad"}
                              />
                              { selectedProduct && <small>max: {productReferenceSelected?.quantity ?? 0}</small> }
                            </div>
                            <div className="col-lg-4 mt-7 mt-lg-0">
                              <Field
                                  type="text"
                                  name="price"
                                  component={Input}
                                  disabled={billing?.id ? true : false}
                                  //value={cop.format(totalBilled)}
                                  value={ billing?.id ? DeleteZerosHelper(cop?.format(Number(billing.totalBilled))) : values.price}
                                  // label={"Total facturación"}
                                  onChange={ (e:any) => {
                                    // setTempValue(Number(e.target.value))
                                    setFieldValue('price', e.target.value)
                                  }}
                                  label={"Precio"}
                              />
                              { selectedProduct && <small>Precio sugerido: {DeleteZerosHelper(cop?.format(Number(productReferenceSelected?.suggestedPrice ?? 0)))} </small> }
                            </div>
                          </div>
                          {/* add button */}
                          <div className="d-flex flex-row-reverse">
                            <div className="col-lg-0">
                              <button type="button" className="btn btn-sm btn-info" onClick={()=>onAddProduct(values, setFieldValue)}>Agregar</button>
                            </div>
                          </div>
                          {/* Tabla de productos */}
                          {
                            availableProduct.length > 0 &&
                              <div className="row mb-7">
                                <ProductTable
                                  id={billing?.id as number}
                                  products={availableProduct}
                                  onDelete={onDeleteProduct}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                          }
                          {/* Descripción del pedido */}
                          <div className="form-group row my-7">
                            {/* Descripcion */}
                            <div className="col-lg-12">
                              <Field
                                name="description"
                                component={TextAreaInput}
                                placeholder={intl.formatMessage({ id: "GENERAL.DESCRIPTION"})}
                                label={intl.formatMessage({ id: "GENERAL.DESCRIPTION"})}
                              />
                            </div>
                          </div>
                          {/* Totales y fecha */}
                          <div className="form-group row my-7">
                            {/* Descuento */}
                            <div className="col-lg-4 mt-6">
                                <Field
                                  type="text"
                                  name="discount"
                                  component={Input}
                                  disabled={billing?.id ? true : false}
                                  // label={"Total facturación"}
                                  onChange={ (e:any) => {
                                    // setTempValue(Number(e.target.value))
                                    if (isNaN(e.target.value)) return Swal.fire({
                                      title: "Por favor ingrese un número válido",
                                      icon: "warning"
                                    })
                                    setDiscount(Number(e.target.value))
                                    const totalBilledAux = lodash.sum(availableProduct.map(x => x.price * x.requestedQuantity))
                                    setFieldValue('total', totalBilledAux-Number(e.target.value))
                                  }}
                                  label={"Descuento"}
                                />
                            </div>
                            {/* Total a facturar */}
                            <div className="col-lg-4 mt-6">
                                <Field
                                  type="text"
                                  name="total"
                                  component={Input}
                                  disabled
                                  value={DeleteZerosHelper(cop?.format(values.total as number))}
                                  // label={"Total facturación"}
                                  onChange={ (e:any) => {
                                    // setTempValue(Number(e.target.value))
                                    // setFieldValue('quantity', e.target.value)
                                  }}
                                  label={"Total"}
                                />
                            </div>
                            {/* Fecha de facturación */}
                            <div className="col-lg-4 mt-6">
                              <label>
                                <b>
                                  {intl.formatMessage({id:"BILLING.DATE"})}
                                </b>
                              </label>
                              <DatePicker
                                name="billingDate"
                                disabled={id ? true : false}
                                size={"large"}
                                // @ts-ignore
                                value={id ? moment(billing.billingDate) : billingDate}
                                placeholder={intl.formatMessage({id:"GENERAL.CHOOSE_DATE"})}
                                style={{width:"100%"}}
                                onChange={(date: any | null, dateString:string):void =>{
                                  setBillingDate(date)
                                  setFieldValue("billingDate",date)
                                }}
                              />
                            </div>
                          </div>
                        </>
                    }
                    {
                      id && <>
                        <div className="form-group row mb-7">
                            <div className="col-lg-4">
                                <Field
                                  name="reference"
                                  component={Input}
                                  disabled={true}
                                  label={"Referencia"}
                                />
                            </div>
                            <div className="col-lg-4">
                                <Field
                                  name="clientName"
                                  component={Input}
                                  disabled={true}
                                  label={"Cliente"}
                                />
                            </div>
                            <div className="col-lg-4">
                                <Field
                                  name="factoryName"
                                  component={Input}
                                  disabled={true}
                                  label={"Fabrica"}
                                />
                            </div>

                        </div>
                        <div className="row mb-7">
                          <ProductTable
                            id={billing?.id as number}
                          // @ts-ignore
                            products={JSON.parse(billing?.options)}
                            onDelete={onDeleteProduct}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        <hr />
                        {/* Descripción del pedido */}
                        <div className="form-group row my-7">
                          {/* Descripcion */}
                          <div className="col-lg-12">
                            <Field
                              name="description"
                              component={TextAreaInput}
                              placeholder={intl.formatMessage({ id: "GENERAL.DESCRIPTION"})}
                              label={intl.formatMessage({ id: "GENERAL.DESCRIPTION"})}
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="form-group row mb-7">
                          {/* Descuento */}
                          <div className="col-lg-4 mt-6">
                                <Field
                                  type="text"
                                  name="discount"
                                  component={Input}
                                  disabled
                                  // @ts-ignore
                                  value={DeleteZerosHelper(cop.format(billing.discount))}
                                  label={"Descuento"}
                                />
                            </div>
                          {/* Total a facturar */}
                          <div className="col-lg-4 mt-6">
                                <Field
                                  type="text"
                                  name="total"
                                  component={Input}
                                  disabled
                                  // @ts-ignore
                                  value={DeleteZerosHelper(cop.format(billing.totalBilled))}
                                  label={"Total"}
                                />
                            </div>
                            <div className="col-lg-4 mt-6">
                              <label>
                                <b>
                                  {intl.formatMessage({id:"BILLING.DATE"})}
                                </b>
                              </label>
                              <DatePicker
                                name="billingDate"
                                disabled={true}
                                size={"large"}
                                // @ts-ignore
                                value={moment(billing.billingDate) }
                                placeholder={intl.formatMessage({id:"GENERAL.CHOOSE_DATE"})}
                                style={{width:"100%"}}
                              />
                            </div>
                        </div>
                      </>
                    }
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                  >
                    {intl.formatMessage({ id: "BUTTON.CANCEL"})}
                  </button>
                  <> </>
                  {
                    !id &&
                      <button
                        type="submit"
                        onClick={() => handleSubmit()}
                        className="btn btn-primary btn-elevate"
                      >
                        {intl.formatMessage({ id: "BUTTON.SAVE"})}
                      </button>
                  }
                  <></>
                  {billing?.id &&(<button
                    type="button"
                    onClick={() =>
                      generateSalesOrder(billing,cop.format)
                    }
                    className = "btn btn-success"
                  >
                    {intl.formatMessage({id:"ORDER.GENERATE.ORDER"})}
                  </button>)}
                </Modal.Footer>
              </>
            )}
          </Formik>
      }
    </>
  );
}



