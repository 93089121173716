import React from 'react'
//@ts-ignore
import ReactExport from 'react-data-export';
import { Download } from "@mui/icons-material";
import { balanceByClient } from'../../app/modules/reports/pages/wallet/WalletUIContext';
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelFile = ReactExport.ExcelFile;

export interface ExcelDetailClientProps{
    data: balanceByClient[];
    clientName: string;
}

const ExcelDetailClient:React.FC<ExcelDetailClientProps> = ({
    data,
    clientName
}) =>{

    const content = data?.map((d) => {
        return [
            {value: d.date},
            {value: d.reference},
            {value: d.description === null ? " " : d.description},
            {value: d.sourceDest},
            {value: d.debt},
            {value: d.payment},
            {value: d.total}
        ]
    })

    const finalData = [
        {
            columns:  [
                {title: "FECHA", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "DDDDDD"}}}},//pixels width 
                {title: "REMISIÓN", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "DDDDDD"}}}},//char width 
                {title: "DESCRIPCIÓN", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "DDDDDD"}}}},
                {title: "DESTINO/ORIGEN", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "DDDDDD"}}}},
                {title: "DEUDA", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "DDDDDD"}}}},
                {title: "ABONO", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "DDDDDD"}}}},
                {title: "TOTAL", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "DDDDDD"}}}},
            ],
            data: content
        }
    ]
   

    return(
        <ExcelFile 
            element={
                <button
                    type="button"
                    className="btn btn-success btn-sm mx-1"
                >
                    Descargar <Download/>
                </button>
            }
            filename={`Detalle${clientName}`}
        >
            <ExcelSheet dataSet={finalData} name="DetalleCliente"/>
        </ExcelFile>

    )
}


export default ExcelDetailClient