import React from "react";
import { LoadingDialog } from "../../../../../../_metronic/partials/control";

export interface OrdersLoadingDialogProps {
  color?: string
}
export const OrdersLoadingDialog: React.FC<OrdersLoadingDialogProps> = ({color}) => {
  return <LoadingDialog color={color} text="Cargando ..." />;
}



