// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
// import * as actions from "../../../_redux/billings/billingsActions";
import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/helpers";
import * as uiHelpers from "../BillingsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/partials/control";
import { useBillingsUIContext, BillingsUIContextModel } from '../BillingsUIContext';
import { useIntl } from "react-intl";
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { Col, Row } from "react-bootstrap";
import DeleteZeros from "../../../../../../utils/functions/DeleteZerosHelper";
import useIntlCurrencyFormat from "../../../../../../utils/customHooks/useIntlCurrencyFormat";

export function BillingsTable() {
  // const { company } = useSelector((state) => state.auth);
  // Billings UI Context
  const billingsUIContext = (useBillingsUIContext() as BillingsUIContextModel);
  const billingsUIProps = useMemo(() => {
    return {
      ids: billingsUIContext.ids,
      setIds: billingsUIContext.setIds,
      queryParams: billingsUIContext.queryParams,
      setQueryParams: billingsUIContext.setQueryParams,
      openEditBillingDialog: billingsUIContext.openEditBillingDialog,
      openDeleteBillingDialog: billingsUIContext.openDeleteBillingDialog,
      openUpdateStatusIsPaid: billingsUIContext.openUpdateStatusIsPaid,
      openRefundBillingDialog: billingsUIContext.openRefundBillingDialog,
      setIsLoading: billingsUIContext.setIsLoading,
      loadingBillings: billingsUIContext.loadBillings,
      tableLoading: billingsUIContext.tableLoading,
      valuesTable: billingsUIContext.valuesTable,
    };
  }, [billingsUIContext]);

  // Getting curret state of billings list from store (Redux)
  // const { currentState } = useSelector(
  //   (state) => ({ currentState: state.billings }),
  //   shallowEqual
  // );
  const { totalCount, data:{entities} } = billingsUIProps.valuesTable;
  const {cop, thousand} = useIntlCurrencyFormat()
  // Billings Redux state
  // Table columns
  const intl = useIntl();
  const columns = [
    {
      dataField: "billingReference",
      text: "referencia",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "products",
      text: "Productos",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "quantity",
      text: "Cantidad",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "clientName",
      text: "Cliente",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "billedPrice",
      text: "Facturado",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "factory",
      text: "Fábrica",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "billingDate",
      text: "Fecha",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent: any, row: any, rowIndex: any,)=> moment(row.billingDate).format('LL')
    },
    {
      dataField: "isPaid",
      text: intl.formatMessage({ id: "GENERAL.STATUS"}),
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "action",
      // text: intl.formatMessage({ id: "FORMULARIES.ACTIONS"}),
      text: 'Acciones',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditBillingDialog: billingsUIProps.openEditBillingDialog,
        openDeleteBillingDialog: billingsUIProps.openDeleteBillingDialog,
        openUpdateStatusIsPaid: billingsUIProps.openUpdateStatusIsPaid,
        openRefundBillingDialog: billingsUIProps.openRefundBillingDialog,
        setIsLoading: billingsUIProps.setIsLoading,
        loadBillings: billingsUIProps.loadingBillings,
        intl,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: [...uiHelpers.sizePerPageList, {text: `${totalCount}`, value: totalCount}],
    sizePerPage: billingsUIProps.queryParams.pageSize,
    page: billingsUIProps.queryParams.pageNumber,
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  })

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={billingsUIProps.tableLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                {...(paginationTableProps)}
                keyField="id"
                data={entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  billingsUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: billingsUIProps.ids,
                //   setIds: billingsUIProps.setIds,
                // })}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
      <hr style={{background: '#bcc3d7', marginInline: '-2.25rem', height: '1px', marginBottom: '2.25rem'}} />
      <h3>Sub Totales:</h3>
      <Row>
        <Col md='6'>
          <strong>Cantidad: </strong>
          {thousand?.format(entities.reduce((p, c) => p + c.quantity, 0))}
        </Col>
        <Col md='6'>
          <strong>Facturado: </strong>
          {DeleteZeros(cop?.format(entities.filter(c => c.deletedAt === null).reduce((p, c) => p + Number(c.billedPrice.split('$')[1].split('.').join('')), 0)))}
        </Col>
      </Row>
    </>
  );
}



