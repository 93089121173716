import React from 'react'
import { ClientModel } from '../../../../admin/pages/client/models/index';

export interface ClientInfoProps {
    client?: ClientModel
}

const ClientInfo:React.FC<ClientInfoProps> = ({
    client
}) => {
    return (
        <>
            {
                client?.id && 
                <>
                    <div className="form-group row my-3">
                        <div className='col-lg-6'> <b>Nombre: </b> {client?.name}</div>
                        <div className='col-lg-6'> <b>Documento: </b> {client?.document}</div>
                    </div>
                    <div className="form-group row my-3">
                        <br />{(client?.address1 || client?.email1 || client?.name1 || client?.phone1) && <div><b>Contacto principal</b></div>} <br />
                        {client.name1 && <div className='col-lg-4'> <b>Nombre: </b> {client?.name1}</div>}
                        {client.email1 && <div className='col-lg-4'> <b>email: </b> {client?.email1}</div>}
                        {client.phone1 && <div className='col-lg-4'> <b>Telefono: </b> {client?.phone1}</div>}
                    </div>
                </>
            }
        </>
    )
}

export default ClientInfo